import React from 'react';

import {FormattedMessage,FormattedNumber,injectIntl,defineMessages} from 'react-intl';
export const GUEST = "0"
export const TEAM = "1"
export const ADMIN = "2"
export const OWNER = "3"

export const UNDEFINED = "0"
export const RESTAURANT = "1"
export const RESTAURANT_CHAIN = "2"
export const RESTAURANT_CORPORATE = "3"
export const CATERING = "4"
export const CATERING_CHAIN = "5"
export const BAKER_PASTRY = "6"
export const BAKER_PASTRY_CHAIN = "7"
export const KITCHEN = "8"
export const FASTFOOD = "9"
export const FOODTRUCK = "10"
export const BAR = "11"
export const HOTEL = "12"
export const HOTEL_CHAIN = "13"
export const MULTISITE = "14"
export const OTHERS = "15"


export const WEIGHT_INSTRUCTION_LINE_TITLE = <FormattedMessage id="newingredient.form.weight.instructions.message.title"
                                                        defaultMessage='units allowed :'/>
export const WEIGHT_INSTRUCTION_LINE_KG =  <FormattedMessage id="newingredient.form.weight.instructions.message.kg"
                                                      defaultMessage='kg, kilo, kilogram, kilograms'/>
export const WEIGHT_INSTRUCTION_LINE_G =  <FormattedMessage id="newingredient.form.weight.instructions.message.g"
                                                      defaultMessage='g, gram, grams'/>
export const WEIGHT_INSTRUCTION_LINE_MG =  <FormattedMessage id="newingredient.form.weight.instructions.message.mg"
                                                      defaultMessage='mg, mgram, mgrams'/>
export const WEIGHT_INSTRUCTION_LINE_OZ =  <FormattedMessage id="newingredient.form.weight.instructions.message.oz"
                                                      defaultMessage='oz, ounce, ounces, once, onces'/>
export const WEIGHT_INSTRUCTION_LINE_LB =  <FormattedMessage id="newingredient.form.weight.instructions.message.lb"
                                                      defaultMessage='lb, lbs, pound, pounds, livre, livres'/>
export const WEIGHT_INSTRUCTION_LINE_EX =  <FormattedMessage id="newingredient.form.weight.instructions.message.ex"
                                                      defaultMessage='example:  3.2 kg , 200g, 2500 mg , 3 oz , 3 lb ... '/>


export const VOLUME_INSTRUCTION_LINE_TITLE = <FormattedMessage id="newingredient.form.volume.instructions.message.title"
                                                      defaultMessage='units allowed :'/>
export const VOLUME_INSTRUCTION_LINE_L =  <FormattedMessage id="newingredient.form.volume.instructions.message.l"
                                                    defaultMessage='l, L, liter, liters, litre, litres'/>
export const VOLUME_INSTRUCTION_LINE_ML =  <FormattedMessage id="newingredient.form.volume.instructions.message.ml"
                                                    defaultMessage='ml, milliliter, milliliters'/>
export const VOLUME_INSTRUCTION_LINE_CL =  <FormattedMessage id="newingredient.form.volume.instructions.message.cl"
                                                    defaultMessage='cl, centiliter, centiliters'/>
export const VOLUME_INSTRUCTION_LINE_FL =  <FormattedMessage id="newingredient.form.volume.instructions.message.fl"
                                                    defaultMessage='floz, fluid-ounce, fluid-ounces'/>
export const VOLUME_INSTRUCTION_LINE_GAL =  <FormattedMessage id="newingredient.form.volume.instructions.message.gal"
                                                    defaultMessage='gal, gallon, gallons'/>
export const VOLUME_INSTRUCTION_LINE_TSP =  <FormattedMessage id="newingredient.form.volume.instructions.message.tsp"
                                                    defaultMessage='tsp, teaspoon, teaspoons, cac, cuillere-cafe'/>
export const VOLUME_INSTRUCTION_LINE_TBSP =  <FormattedMessage id="newingredient.form.volume.instructions.message.tbsp"
                                                    defaultMessage='tbsp, tablespoon, tablespoons, cas, cuillere-soupe'/>
export const VOLUME_INSTRUCTION_LINE_EX =  <FormattedMessage id="newingredient.form.volume.instructions.message.ex"
                                                    defaultMessage='example:  3.2L , 900ml, 20cl,  20 floz ...  '/>
export const VOLUME_INSTRUCTION_LINE_EX_RECIPE =  <FormattedMessage id="newingredient.form.volume.instructions.recipe.message.ex"
                                                           defaultMessage='example:  3.2L , 900ml, 20cl, 4 tsp,  20 floz ...  '/>

export const QUANTITY_INSTRUCTION_LINE_TITLE = <FormattedMessage id="newingredient.form.quantity.instructions.message.title"
                                                      defaultMessage='quantity number'/>
export const QUANTITY_INSTRUCTION_LINE_NUMBER = <FormattedMessage id="newingredient.form.quantity.instructions.message.number"
                                                      defaultMessage='number of units (numeric)'/>                                                      
export const QUANTITY_INSTRUCTION_LINE_EX =  <FormattedMessage id="newingredient.form.quantity.instructions.message.ex"
                                                      defaultMessage='example:  12 , 5, 20  ...  '/>

export const RECIPE_QUANTITY_INSTRUCTION_LINE_TITLE = <FormattedMessage id="newingredient.form.quantity.instructions.recipe.message.title"
                                                      defaultMessage='Number'/>
export const RECIPE_QUANTITY_INSTRUCTION_LINE_NUMBER = <FormattedMessage id="newingredient.form.quantity.instructions.recipe.message.line1"
                                                      defaultMessage='Number of'/>                                                      
export const RECIPE_QUANTITY_INSTRUCTION_LINE_EX =  <FormattedMessage id="newingredient.form.quantity.instructions.recipe.message.line2"
                                                      defaultMessage='Recipe yield is'/>


/* export const ALLERGENS_GLUTEN = <FormattedMessage id="newingredient.allergens.gluten.string"
                                                  defaultMessage='gluten'/>
export const ALLERGENS_CRUSTACEANS = <FormattedMessage id="newingredient.allergens.crustaceans.string"
                                                  defaultMessage='crustaceans'/>
export const ALLERGENS_EGG = <FormattedMessage id="newingredient.allergens.egg.string"
                                                  defaultMessage='egg'/>          
export const ALLERGENS_FISH = <FormattedMessage id="newingredient.allergens.fish.string"
                                                  defaultMessage='fish'/>
export const ALLERGENS_PEANUTS = <FormattedMessage id="newingredient.allergens.peanuts.string"
                                                  defaultMessage='peanuts'/>
export const ALLERGENS_SOYA = <FormattedMessage id="newingredient.allergens.soya.string"
                                                  defaultMessage='soya'/>                                                     
export const ALLERGENS_MILK = <FormattedMessage id="newingredient.allergens.milk.string"
                                                  defaultMessage='milk'/>
export const ALLERGENS_NUTS = <FormattedMessage id="newingredient.allergens.nuts.string"
                                                  defaultMessage='nuts'/>         
export const ALLERGENS_CELERY = <FormattedMessage id="newingredient.allergens.celery.string"
                                                  defaultMessage='celery'/>
export const ALLERGENS_MUSTARD = <FormattedMessage id="newingredient.allergens.mustard.string"
                                                  defaultMessage='mustard'/>
export const ALLERGENS_SESAME = <FormattedMessage id="newingredient.allergens.sesame.string"
                                                  defaultMessage='sesame'/>  
export const ALLERGENS_SULPHITES = <FormattedMessage id="newingredient.allergens.sulphites.string"
                                                  defaultMessage='sulphites'/>
export const ALLERGENS_LUPIN = <FormattedMessage id="newingredient.allergens.lupin.string"
                                                  defaultMessage='lupin'/>
export const ALLERGENS_MOLLUSCS = <FormattedMessage id="newingredient.allergens.molluscs.string"
                                                  defaultMessage='molluscs'/> 
 */
export const allergens = defineMessages({
          gluten: {
                    id : "newingredient.allergens.gluten.string",
                    defaultMessage: "Gluten",
                  },
          crustaceans: {
                    id : "newingredient.allergens.crustaceans.string",
                    defaultMessage: "Crustaceans",
                  },
          egg:    {
                    id : "newingredient.allergens.egg.string",
                    defaultMessage: "Eggs",
                  },
          fish:  {
                    id : "newingredient.allergens.fish.string",
                    defaultMessage: "Fish",
                  },
          peanuts: {
                    id : "newingredient.allergens.peanuts.string",
                    defaultMessage: "Peanuts",
                  },
          soya: {
                    id : "newingredient.allergens.soya.string",
                    defaultMessage: "Soya",
                  },
          milk: {
                    id : "newingredient.allergens.milk.string",
                    defaultMessage: "Milk",
                  },
          nuts: {
                    id : "newingredient.allergens.nuts.string",
                    defaultMessage: "Nuts",
                  },
          celery: {
                    id : "newingredient.allergens.celery.string",
                    defaultMessage: "Celery",
                  },
          mustard: {
                    id : "newingredient.allergens.mustard.string",
                    defaultMessage: "Mustard",
                  },
          sesame: {
                    id : "newingredient.allergens.sesame.string",
                    defaultMessage: "Sesame",
                  },
         sulphites: {
                    id : "newingredient.allergens.sulphites.string",
                    defaultMessage: "Sulphites",
                  },
          lupin: {
                    id : "newingredient.allergens.lupin.string",
                    defaultMessage: "Lupin",
                  },
          molluscs: {
                    id : "newingredient.allergens.molluscs.string",
                    defaultMessage: "Molluscs",
                  },

  });


  export const csv_columns = defineMessages({
    not_used:{   
            id:"importingredients.options.columns.na",
            defaultMessage:"not used",
          },
    category: { 
           id:"importingredients.options.columns.category",
           defaultMessage:"category",
          },
    supplier: { 
          id:"importingredients.options.columns.supplier",
          defaultMessage:"supplier",
        },
    name: { 
          id:"importingredients.options.columns.name",
          defaultMessage:"name",
        },
    price: {
          id:"importingredients.options.columns.price",
          defaultMessage:"price",
        },
    quantity: { 
         id:"importingredients.options.columns.quantity",
         defaultMessage:"quantity",
      },
    unit : {
         id:"importingredients.options.columns.unit",
         defaultMessage:"unit",
      },
    pack : {
        id:"importingredients.options.columns.pack",
        defaultMessage:"case size",
     },
    internal_code: { 
        id:"importingredients.options.columns.internal_code",
        defaultMessage:"internal code",
      },
    supplier_code: { 
        id:"importingredients.options.columns.supplier_code",
        defaultMessage:"supplier code",
      },
    note: { 
        id:"importingredients.options.columns.note",
        defaultMessage:"note",
      },
    allergen: { 
        id:"importingredients.options.columns.allergen",
        defaultMessage:"allergen",
      },
    storage: { 
        id:"importingredients.options.columns.storage",
        defaultMessage:"storage",
      },
    tax: { 
        id:"importingredients.options.columns.tax",
        defaultMessage:"tax",
      },
     date_expire: { 
        id:"importingredients.options.columns.date_expire",
        defaultMessage:"use by",
      },
     batch_number: { 
        id:"importingredients.options.columns.batch_number",
        defaultMessage:"batch number",
      },
  });


  export const csv_sells_columns = defineMessages({
    not_used:{   
            id:"importsales.options.columns.na",
            defaultMessage:"not used",
          },
    name: { 
          id:"importsales.options.columns.name",
          defaultMessage:"recipe name",
        },
    sells: {
          id:"importsales.options.columns.sells",
          defaultMessage:"sells",
        },
    quantity: { 
         id:"importsales.options.columns.quantity",
         defaultMessage:"quantity",
      },
    pu : {
         id:"importsales.options.columns.pu",
         defaultMessage:"unit price",
      },
    internal_code: { 
        id:"importsales.options.columns.internal_code",
        defaultMessage:"internal code",
      },
    tax: { 
        id:"importsales.options.columns.tax",
        defaultMessage:"sales tax",
      },
  });


export const USER_ROLE_ADMIN = <FormattedMessage id="dashboard.user.role.admin.title"
                                                defaultMessage='Admin'/>
export const USER_ROLE_OWNER = <FormattedMessage id="dashboard.user.role.owner.title"
                                                defaultMessage='Admin (owner)'/>
export const USER_ROLE_TEAM = <FormattedMessage id="dashboard.user.role.team.title"
                                                 defaultMessage='Team'/>
export const USER_ROLE_GUEST = <FormattedMessage id="dashboard.user.guest.team.title"
                                                 defaultMessage='Guest'/>

export function extractRoleFromCode(x){
  if (x == ADMIN){
      return USER_ROLE_ADMIN
  }else if (x == OWNER){
      return USER_ROLE_OWNER
  }else if (x == TEAM){
      return USER_ROLE_TEAM
  }else {
      return USER_ROLE_GUEST
  }
}


export const CompanyTypeOptions = [
  { key: UNDEFINED, text: <FormattedMessage id="dashboard.menu.options.undefined.text"
                                            defaultMessage="Undefined"/>, value: UNDEFINED },
  { key: RESTAURANT, text: <FormattedMessage id="dashboard.menu.options.restaurant.text"
                                         defaultMessage="Restaurant"/>, value: RESTAURANT },
  { key: RESTAURANT_CORPORATE, text: <FormattedMessage id="dashboard.menu.options.restaurant_corporate.text"
                                         defaultMessage="Restaurant Corporate"/>, value: RESTAURANT_CORPORATE},
  { key: RESTAURANT_CHAIN, text: <FormattedMessage id="dashboard.menu.options.restaurant_chain.text"
                                            defaultMessage="Restaurant Chain"/>, value: RESTAURANT_CHAIN},
  { key: CATERING, text: <FormattedMessage id="dashboard.menu.options.catering.text"
                                          defaultMessage="Catering"/>, value: CATERING },
  { key: CATERING_CHAIN, text: <FormattedMessage id="dashboard.menu.options.catering_chain.text"
                                          defaultMessage="Catering Chain"/>, value: CATERING_CHAIN },
  { key: BAKER_PASTRY, text: <FormattedMessage id="dashboard.menu.options.baker_pastry.text"
                                          defaultMessage="Baker Pastry shop"/>, value: BAKER_PASTRY },
  { key: BAKER_PASTRY_CHAIN, text: <FormattedMessage id="dashboard.menu.options.baker_pastry_chain.text"
                                          defaultMessage="Baker Pastry Chain"/>, value: BAKER_PASTRY_CHAIN },
  { key: KITCHEN, text: <FormattedMessage id="dashboard.menu.options.kitchen.text"
                                          defaultMessage="Dark kitchen"/>, value: KITCHEN },
  { key: FASTFOOD, text: <FormattedMessage id="dashboard.menu.options.fastfood.text"
                                          defaultMessage="Fast food"/>, value: FASTFOOD },
  { key: FOODTRUCK, text: <FormattedMessage id="dashboard.menu.options.foodtruck.text"
                                          defaultMessage="Food Truck"/>, value: FOODTRUCK },
  { key: BAR, text: <FormattedMessage id="dashboard.menu.options.bar.text"
                                          defaultMessage="Bar - Night club"/>, value: BAR },
  { key: HOTEL, text: <FormattedMessage id="dashboard.menu.options.hotel.text"
                                          defaultMessage="Hotel"/>, value: HOTEL },
  { key: HOTEL_CHAIN, text: <FormattedMessage id="dashboard.menu.options.hotel_chain.text"
                                          defaultMessage="Hotel Chain"/>, value: HOTEL_CHAIN },
  { key: MULTISITE, text: <FormattedMessage id="dashboard.menu.options.multisite.text"
                                          defaultMessage="Multisite"/>, value: MULTISITE },
  { key: OTHERS, text: <FormattedMessage id="dashboard.menu.options.others.text"
                                          defaultMessage="Other"/>, value: OTHERS },
]