import React from 'react';
import { Icon ,Message} from 'semantic-ui-react';
import ButterToast, {Cinnamon,Crisp}  from 'butter-toast';
import { alertActions } from '../_actions';
import { alertConstants } from '../_constants';

const  WELCOME_MESSAGE_ID = "user.actions.login.message.welcome.content"


/* export function toaster_dispatch(dispatch,alert){
    if (alert.type == alertConstants.SUCCESS){
        if (alert.message && alert.message.content == WELCOME_MESSAGE){
            toaster_hello(alert.message.title,alert.message.content);
        }else{
            toaster_info(alert.message.title,alert.message.content);
        }
    }
    if (alert.type == alertConstants.ERROR){
        toaster_error(alert.message.statusText,alert.message.message);
    }

    dispatch(alertActions.clear());
}
 */
export function toaster_dispatch(dispatch,alert,intl,message){

    if (alert.type === alertConstants.SUCCESS && !alert.message.stringMode){
        if (alert.message && alert.message.content === WELCOME_MESSAGE_ID){
            toaster_hello(intl.formatMessage({ id: alert.message.title },
                                             { username: alert.message.vars }),
                          intl.formatMessage({ id: alert.message.content }));
        }else{
            toaster_info(intl.formatMessage({ id: alert.message.title }),
                          intl.formatMessage({ id: alert.message.content }));
        }
    }
    if (alert.type === alertConstants.ERROR && !alert.message.stringMode) {
        //toaster_error(alert.message.statusText,alert.message.message);
        toaster_error(intl.formatMessage({ id: alert.message.title},
                                         { message: alert.message.vars}),
                      intl.formatMessage({ id: alert.message.content},
                                         { message: alert.message.vars }));

    }

    if (alert.type === alertConstants.ERROR && alert.message.stringMode) {
        //toaster_error(alert.message.statusText,alert.message.message);
        toaster_error(alert.message.title,
                      alert.message.content);

    }


    if (alert.type === alertConstants.WARN && !alert.message.stringMode) {
        //toaster_error(alert.message.statusText,alert.message.message);
        toaster_warn(intl.formatMessage( { id: alert.message.title },
                                         { message: alert.message.vars }),
                      intl.formatMessage({ id: alert.message.content },
                                         { message: alert.message.vars }));
    }

    if (alert.type === alertConstants.WARN && alert.message.stringMode) {
        //toaster_error(alert.message.statusText,alert.message.message);
        toaster_warn(alert.message.title,
                     alert.message.content);

    }


    if (message && !alert.message.stringMode && (alert.type === alertConstants.ERROR)){
        return (
            <Message negative>
                      <Message.Header>{intl.formatMessage({ id: alert.message.title })}</Message.Header>
                      <p>{intl.formatMessage({ id: alert.message.content })}</p>
            </Message>
        )
    }
    else if (message && alert.message.stringMode && (alert.type === alertConstants.ERROR)){
        return (
            <Message negative>
                      <Message.Header>{alert.message.title }</Message.Header>
                      <p>{alert.message.content}</p>
            </Message>
        )
    }
    else{
        dispatch(alertActions.clear());
    }
}

export function toaster_info(title,message,timeout=200){
 
    let scheme = 'scheme-blue';
    let icon= <Icon size='big' name='info'/>

    setTimeout(() => {
        ButterToast.raise({
            content: <Cinnamon.Crisp title={title}
                    content={message}
                    scheme={scheme}
                    icon={icon}/>}); 
    },timeout);

}

export function toaster_warn(title,message,timeout=250){
 
    let scheme = 'scheme-orange'; 
    let icon= <Icon size='big' name='exclamation'/>

    setTimeout(() => {
        ButterToast.raise({
            content: <Cinnamon.Crisp title={title}
                    content={message}
                    scheme={scheme}
                    icon={icon}/>}); 
    },timeout);

}

export function toaster_error(title,message,timeout=250){
 
    let scheme = 'scheme-red';
    let icon= <Icon size='big' name='exclamation'/>

    setTimeout(() => {
        ButterToast.raise({
            content: <Cinnamon.Crisp title={title}
                    content={message}
                    scheme={scheme}
                    icon={icon}/>}); 
    },timeout);

}



export function toaster_hello(title,message,timeout=200){
 
    let scheme = 'scheme-blue';
    let icon= <Icon size='big' name='home'/>

    setTimeout(() => {
        ButterToast.raise({
            content: <Cinnamon.Crunch title={title}
                    content={message}
                    scheme={scheme}
                    icon={icon}/>}); 
    },timeout);

}