import React, { Component } from 'react'
import { Sidebar, Segment, Responsive, Menu, List, Container, Icon,Button} from 'semantic-ui-react'
import { Link,withRouter } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import { connect } from 'react-redux';
import {history} from '../_helpers';
import {styles} from './Styles';

const containerStyle = {
  height: 'auto',
  width: '1400px',
  padding: '1em 0em',
  border:'none',
  'font-family': 'Lato', 
  'font-size': '12px',
  'color': 'grey',
  //'border-style':'solid',
  //'border-width': '0.5px',
 'background-color': '#f9f9f9',
 // backgroundImage: `url(${backgroundImg})`,
 // backgroundSize: 'cover'
};

class SideLeftUncoverSales extends Component {
  state = { visible: false }

  isActive(match1){
    //return this.props.location.pathname.indexOf(match1)!=-1
    return this.props.location.pathname === match1
  }

  onSubmit = (e) => {
    e.preventDefault();
    history.push({pathname: '/profilepremium', state: {toggled: true}})
  }

  render() {
    const {subscription} = this.props;
    return (
      <div>
      <Responsive minWidth={Responsive.onlyComputer.minWidth} >
          <Sidebar.Pushable as={Container} style={containerStyle}>
            <Sidebar as={List} selection animation='uncover' width='thin' visible={true} icon='labeled' vertical size='large'>
              <br/>
               <List.Item disabled>
                       <FormattedMessage id="sales.computer.sidemenu.menu.top.title"
                                         defaultMessage='Sales Actions'/>
               </List.Item>
               <List.Item name='View Sales' as={Link} to='/sales' >
                  <Icon name='home' color={this.isActive('/sales')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('/sales')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="sales.computer.sidemenu.menu.elem1.title"
                                          defaultMessage='Sales'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
               <List.Item name='Import sales' as={Link} to={'/importsales'}>
                  <Icon name='cloud upload' color={this.isActive('/importsales')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('/importsales')?{'color': '#0d77b5'}:{'color': 'black'}}>
                         <FormattedMessage id="sales.computer.sidemenu.menu.elem2.title"
                                          defaultMessage='Import sales'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                {subscription && (!subscription.customer__membership__membership_type || subscription.customer__membership__membership_type=="Free") && this.isActive('/ingredients') &&
                    <div style={{'text-align':'center', 'paddingTop' : '22em'}}>
                      <a style={{color:'grey'}}>
                            <FormattedMessage id="sales.computer.sidemenu.premium.title.message.subscribe"
                                              defaultMessage='Plan: Basic'/>
                      </a>
                      <Button style={styles.ButtonBluePremium}
                        onClick={(e) => {this.onSubmit(e)}}>
                        <FormattedMessage id="sales.button.premium"
                                          defaultMessage='Subscribe'/>
                      </Button>
                </div>}
            </Sidebar>
            <Sidebar.Pusher style={{ 'min-height': '100vh',overflowY: 'auto', width: '100%', padding: '2em 2em 20em 3em','background-color':'#fcfcfc'}}>
                  {this.props.children} 
              </Sidebar.Pusher>
          </Sidebar.Pushable>
        {/* </Segment> */}
      </Responsive>
      <Responsive minWidth={Responsive.onlyMobile.maxWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
          <Menu secondary>
              <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                  <Icon name="sidebar"/><FormattedMessage id="sales.mobile.sidemenu.menu.top.title"
                                                          defaultMessage='Sales'/>
              </Menu.Item>          
          </Menu>
          <Sidebar.Pushable as={Segment}>
              <Sidebar 
                  width='thin'
                  as={Menu} 
                  animation="push" 
                  direction="top" 
                  visible={this.state.visible} 
                  icon="labeled" 
                  horizontal
                  size='tiny'>
                  {/* <Container> */}
                    <Menu.Item name='View'as={Link} to='/sales'>
                        <Icon name='home' color={this.isActive('/sales')?'blue': 'black'}/>
                          <FormattedMessage id="sales.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='View'/>
                      </Menu.Item>
                    <Menu.Item name='Import' as={Link} to='/importsales' >
                        <Icon name='cloud upload' color={this.isActive('/importsales')?'blue': 'black'}/>
                        <FormattedMessage id="sales.mobile.sidemenu.menu.elem7.title"
                                          defaultMessage='Import sales'/>
                        {/*  <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem7.subtitle"
                                          defaultMessage='or add new items'/> */}
                    </Menu.Item>
                {/* </Container>   */}
              </Sidebar>
              <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto', padding: '2em 0em 20em 2em' }}>
                    {this.props.children}
              </Sidebar.Pusher>
          </Sidebar.Pushable>
       </Responsive>
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <Menu secondary>
              <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                  <Icon name="sidebar"/><FormattedMessage id="sales.mobile.sidemenu.menu.top.title"
                                                          defaultMessage='Sales'/>
              </Menu.Item>          
          </Menu>
          <Sidebar.Pushable as={Segment}>
              <Sidebar 
                  width='thin'
                  as={Menu} 
                  animation="push" 
                  direction="top" 
                  visible={this.state.visible} 
                  icon="labeled" 
                  horizontal
                  size='tiny'>
                  {/* <Container> */}
                    <Menu.Item name='View'as={Link} to='/sales'>
                        <Icon name='home' color={this.isActive('/sales')?'blue': 'black'}/>
                          <FormattedMessage id="sales.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='View'/>
                      </Menu.Item>
                    <Menu.Item name='Import' as={Link} to='/importsales' >
                        <Icon name='cloud upload' color={this.isActive('/importsales')?'blue': 'black'}/>
                        <FormattedMessage id="sales.mobile.sidemenu.menu.elem7.title"
                                          defaultMessage='Import sales'/>
                    </Menu.Item>
                {/* </Container>   */}
              </Sidebar>
              <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto', padding: '2em 0em 20em 0em' }}>
                    {this.props.children}
              </Sidebar.Pusher>
          </Sidebar.Pushable>
       </Responsive>
    </div>
    )
  }
}

function mapStateToProps(state) {
  const { subscription } = state.payment;
  return {
      subscription,
  };
}
export default connect(mapStateToProps)(withRouter(SideLeftUncoverSales))
