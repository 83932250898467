import React, { Component } from 'react';
import { Table, Header, Button, Grid,Dropdown,Popup,Input,Icon,Dimmer,Loader,Sidebar,Segment,Form,Message,Image,Breadcrumb,Modal,TextArea } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {alertActions,recipesActions,categoryRecipeActions,paymentActions,clientActions} from '../_actions'
import SideLeftUncoverProductions from './SidebarProductions';
import {history,toaster_dispatch,isNumeric,toaster_info,trim,unitWeight,unitVolume, MAX_NUMBER_RECIPES_FREE_SUBSCRIBER, 
    typeYield,isWeight,isVolume,mgrams,kgrams,cliters,grams,mliters,liters,pack_unit_key_reserved,optimizeDecimalPart,isFloat,GUEST,isQuantity} from '../_helpers';

import {FormattedMessage,FormattedNumber,injectIntl} from 'react-intl';
import {isEmpty,uniqueId} from "lodash";
import {styles} from './Styles';

import img_pan from '../Assets/pan.png'
import img_invoicing from '../Assets/invoicing.png'
import img_performance from '../Assets/performance.png'
import './FloatingMenu.css';
import OutsideClickHandler from 'react-outside-click-handler';

import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber,isPossiblePhoneNumber } from 'react-phone-number-input'

const DUPLICATE_ADD_RECIPE_MESSAGE_TITLE = <FormattedMessage id="estimate.toaster.recipe.add.duplicate.message.title"
                                                  defaultMessage='Adding recipe ...'/>
         
const DUPLICATE_ADD_RECIPE_MESSAGE_CONTENT = <FormattedMessage id="estimate.toaster.recipe.add.duplicate.message.content"
                                                  defaultMessage='Already added to your order'/>


const PRODUCTION_STARTED_MESSAGE_TITLE = <FormattedMessage id="estimate.toaster.production.started.message.title"
                                                  defaultMessage='Estimate info'/>
         
const PRODUCTION_STARTED_MESSAGE_CONTENT = <FormattedMessage id="estimate.toaster.production.started.message.content"
                                                  defaultMessage='Estimate added to production list'/>      

const BILLING_STARTED_MESSAGE_TITLE = <FormattedMessage id="estimate.toaster.billing.started.message.title"
                                                  defaultMessage='Estimate info'/>
         
const  BILLING_STARTED_MESSAGE_CONTENT = <FormattedMessage id="estimate.toaster.billing.started.message.content"
                                                  defaultMessage='Estimate items billed, see in sales'/>    

                                    
const  CREATE_PDF_AND_SEND_TITLE = <FormattedMessage id="estimate.toaster.pdf.send.message.title"
                                                  defaultMessage='Create PDF and Send'/> 
const  CREATE_PDF_AND_SEND_CONTENT = <FormattedMessage id="estimate.toaster.pdf.send.message.content"
                                                  defaultMessage='Document created and sent by email with success'/> 


const UNITS = <FormattedMessage id="estimate.quantity.unit.plural.text"
                        defaultMessage='unit(s)'/>
                       
//['draft','sent','production ongoing','refused','ended','paid','not recoverable']
const optionsStatus = [
                { key: 1, text: <FormattedMessage id="estimate.options.draft"
                                                  defaultMessage='Draft'/>, value: 'draft' },
                 { key: 2, text: <FormattedMessage id="estimate.options.sent"
                                                   defaultMessage='Sent'/>, value: 'sent' },
                 { key: 3, text: <FormattedMessage id="estimate.options.accepted"
                                                    defaultMessage='Accepted'/>, value: 'accepted' },
                 { key: 4, text: <FormattedMessage id="estimate.options.onproducing"
                                                  defaultMessage='Production ongoing'/>, value: 'production' },
                { key: 5, text: <FormattedMessage id="estimate.options.refused"
                                                     defaultMessage='Refused'/>, value: 'refused' },
                { key: 6, text: <FormattedMessage id="estimate.options.ended"
                                                     defaultMessage='Ended'/>, value: 'ended' }                                                           
            ]                     


class EstimateDetails extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        const {location,intl} = props;
        this.filterNameList=this.filterNameList.bind(this);
        this.filterCategoryList=this.filterCategoryList.bind(this);

        this.state = { prevRecipe : props.recipes,
                       filtering: false,
                       orderList:location && location.state && location.state.recipesList?location.state.recipesList : [],
                       availableRecipesList:[],
                       emptyOrders: true,
                       units: location && location.state && location.state.recipesUnits?location.state.recipesUnits : [],
                       isMobile: false,
                       currency : userIn.user.currency,
                       newQuantity:0,
                       estimateName :  uniqueId('ESTIMATE_'),
                       currentDate: new Date(),
                       dueDate: null,
                       note:"",
                       activeModalPremium: false,
                       status:'draft',
                       editCell: false,
                       activeModalRecipeDelete: false,
                       activeModalNewClient: false,
                       client_name: "",
                       client_company: "",
                       client_phone: "",
                       client_address: "",
                       client_zipcode: "",
                       client_city: "",
                       client_country: "",
                       client_state: "",
                       client_email:"",
                       client_note:"",
                       client_selected: 0,
                    }

        props.dispatch(recipesActions.getOrdersRecipes(0,'infinite', 0));
        props.dispatch(recipesActions.getEstimate(props.match.params.estimateId));
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(categoryRecipeActions.getAll()); 
        props.dispatch(paymentActions.getCustomerMembership());
        props.dispatch(clientActions.getAll());
        this.hideModal = this.hideModal.bind(this);
        this.getUpdateTimeout = null;

        registerLocale('en', en)
        registerLocale('fr', fr)
        if(intl.locale == 'fr'){
            setDefaultLocale('fr',fr)
        }else{
            setDefaultLocale('en',en)
        }
    }


    static getDerivedStateFromProps(props, state) {
        if ((props.recipes!==state.prevRecipe) &&  (props.recipes.estimate)) {
            let estimate = props.recipes.estimate;
            let aggRecipes = [];

            if (estimate.items && estimate.items.length > 0){
                for (let l of estimate.items){
                    if (l.item){
                        aggRecipes.push({ id: l.item.id,
                                         item: l.item,   
                                         value: l.unit, 
                                         quantity_type:typeYield( l.item.yield_units),
                                         is_nb_recipes: l.is_recipes_unit,
                                         unit: l.item.yield_units.toLowerCase()
                        })
                    }
                 }
            
            aggRecipes.sort(function (a, b) {
                    if (a.item.category  === null) 
                      return 1;
                    else if ( b.item.category === null)
                      return -1;
                    else if (a.item.category && b.item.category){
                      return a.item.category.name.toLowerCase() > b.item.category.name.toLowerCase();
                    }
            })
            
            if (props.recipes.items){
                return { prevRecipe : props.recipes,
                    currentDate: new Date(estimate.created),
                    dueDate: new Date(estimate.due),
                    estimateName: estimate.ref_name,
                    reference: estimate.ref_number,
                    client_selected: (estimate.client!=null) ? estimate.client.id : "",
                    status : estimate.state,
                    vat: Number(estimate.selling_vat),
                    orderList: aggRecipes,
                    note: estimate.note,
                    emptyOrders: false,
                    availableRecipesList: props.recipes.items
                   };

            }else{
                return { prevRecipe : props.recipes,
                    currentDate: new Date(estimate.created),
                    dueDate: new Date(estimate.due),
                    estimateName: estimate.ref_name,
                    reference: estimate.ref_number,
                    client_selected :  (estimate.client!=null) ? estimate.client.id : "",
                    status : estimate.state,
                    vat: Number(estimate.selling_vat),
                    orderList: aggRecipes,
                    note: estimate.note,
                    emptyOrders: false,
                   };
            }
            }
        }
        if((props.recipes!==state.prevRecipe)&&(props.recipes.items)){
            return { prevRecipe : props.recipes,
                     availableRecipesList: props.recipes.items };
        }
        return null;
    }

    componentDidMount() {
        const {orderList} = this.state;
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if (orderList && orderList.length > 0){
            //this.setState({ orderList:orderList});
            this.setState({ emptyOrders: false});
            history.replace(this.props.location.state, null);
        }
        //this.setState({ selectedDate: this.props.location.state.date});
    }

    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setCurrentDate (date){
        const {dispatch,estimate} = this.props;
        this.setState({ currentDate: date});

        clearTimeout(this.getUpdateTimeout);        
        let updateProps = {
            'ref_name' :  trim(this.state.estimateName).substring(0,80),
            'ref_number' : trim(this.state.reference).substring(0,80),
            'type' : 'estimate',
            'created': date,
            'due': this.state.dueDate,
            'client_id' :  (this.state.client_selected != null)&&(this.state.client_selected != "") ? this.state.client_selected:0,
            'state': this.state.status,
            'note': this.state.note,
        } 

        this.getUpdateTimeout =  setTimeout(() =>  { dispatch(recipesActions.updateEstimateInfos(estimate.id,updateProps)); } , 2000);
    }

    setDueDate (date){
        const {dispatch,estimate} = this.props;
        this.setState({ dueDate: date});

        clearTimeout(this.getUpdateTimeout);        
        let updateProps = {
            'ref_name' :  trim(this.state.estimateName).substring(0,80),
            'ref_number' : trim(this.state.reference).substring(0,80),
            'type' : 'estimate',
            'created': this.state.currentDate,
            'due': date,
            'client_id' : (this.state.client_selected != null)&&(this.state.client_selected != "") ? this.state.client_selected:0,
            'state': this.state.status,
            'note': this.state.note,
        } 

        this.getUpdateTimeout =  setTimeout(() =>  { dispatch(recipesActions.updateEstimateInfos(estimate.id,updateProps)); } , 2000);
    }

     onNoteChange = (e) => {
        const {dispatch,estimate} = this.props;
        e.persist();
        this.setState({ note:  e.target.value  });

        clearTimeout(this.getUpdateTimeout);        
        let updateProps = {
            'ref_name' :  trim(this.state.estimateName).substring(0,80),
            'ref_number' : trim(this.state.reference).substring(0,80),
            'type' : 'estimate',
            'created': this.state.currentDate,
            'due': this.state.dueDate,
            'client_id' : (this.state.client_selected != null)&&(this.state.client_selected != "") ? this.state.client_selected:0,
            'state': this.state.status,
            'note': e.target.value,
        } 

        this.getUpdateTimeout =  setTimeout(() =>  { dispatch(recipesActions.updateEstimateInfos(estimate.id,updateProps)); } , 2000);
    }


    onClientChange = (e,data) => {
        e.preventDefault();
        const {client,estimate,dispatch} = this.props;
        if (data.value==""){
          this.setState({ client_selected: "" });
          data.value = 0
        }else{

          this.setState({ client_selected:data.value});
        }

        clearTimeout(this.getUpdateTimeout);        
        let updateProps = {
            'ref_name' :  trim(this.state.estimateName).substring(0,80),
            'ref_number' : trim(this.state.reference).substring(0,80),
            'type' : 'estimate',
            'created': this.state.currentDate,
            'due': this.state.dueDate,
            'client_id' :  (data.value != null) ? data.value:0,
            'state': this.state.status,
            'note':this.state.note,
        } 

        this.getUpdateTimeout =  setTimeout(() =>  { dispatch(recipesActions.updateEstimateInfos(estimate.id,updateProps)); } , 2000);
    }
 
    onStatusChange = (e, data) => {
        const {dispatch,estimate} = this.props;
        e.persist();
        this.setState({ status: data.value});

        clearTimeout(this.getUpdateTimeout);        
        let updateProps = {
            'ref_name' :  trim(this.state.estimateName).substring(0,80),
            'ref_number' : trim(this.state.reference).substring(0,80),
            'type' : 'estimate',
            'created': this.state.currentDate,
            'due': this.state.dueDate,
            'client_id' : (this.state.client_selected != null)&&(this.state.client_selected != "") ? this.state.client_selected:0,
            'state': data.value,
            'note':this.state.note,
        } 

        this.getUpdateTimeout =  setTimeout(() =>  { dispatch(recipesActions.updateEstimateInfos(estimate.id,updateProps)); } , 2000);

    }

    onNameChange = (e) => {
         const {dispatch,estimate} = this.props;
         e.persist();
         this.setState({ estimateName: e.target.value });
 
         clearTimeout(this.getUpdateTimeout);        
         let updateProps = {
             'ref_name' :  trim(e.target.value).substring(0,80),
             'ref_number' : trim(this.state.reference).substring(0,80),
             'type' : 'estimate',
             'created': this.state.currentDate,
             'due': this.state.dueDate,
             'client_id' :  (this.state.client_selected != null)&&(this.state.client_selected != "") ? this.state.client_selected:0,
             'state': this.state.status,
             'note':this.state.note,
         } 
 
         this.getUpdateTimeout =  setTimeout(() =>  { dispatch(recipesActions.updateEstimateInfos(estimate.id,updateProps)); } , 2000);
    }
 
    seeRecipe (event,index) {
        event.preventDefault();
        history.push('/recipedetails/'+index);
    }

    createRecipe(){
        history.push('/newrecipe/');
    }

    deleteRecipeClickHandler (event,index,item) {
        event.preventDefault();
        this.setState({ activeModalRecipeDelete: index});
        this.setState({ item_estimate : item});
    };

    deleteRecipeEstimate(event){
        event.preventDefault();
        const {estimate} = this.props;
        const { item_estimate } = this.state;
        this.props.dispatch(recipesActions.deleteRecipeEstimate(estimate.id,
                                                               {invoicing_recipe_id:item_estimate.id}));  
    }

    addClientClickHandler (event) {
        event.preventDefault();
        this.setState({ activeModalNewClient: true});
    };

    editClientsListHandler (event) {
        event.preventDefault();
        history.push('/clients/');
    };

    OnNameChange = (e) => {
         this.setState({ client_name: e.target.value });
    }
    OnCompanyChange = (e) => {
         this.setState({ client_company: e.target.value });
    }
    
    OnEmailChange = (e) => {
         this.setState({ client_email: e.target.value });
    }

    OnPhoneChange = (number) => {
        if (number == undefined){
            number = ''
        }

        this.setState({ client_phone: number });
    }
    OnAdressChange = (e) => {
        this.setState({ client_address: e.target.value });
    }

    OnCityChange = (e) => {
        this.setState({ client_city: e.target.value });
    }

    OnCountryChange = (e) => {
       this.setState({ client_country: e.target.value });
    }

    OnZipcodeChange = (e) => {
       this.setState({ client_zipcode: e.target.value });
    } 

   OnStateChange = (e) => {
       this.setState({ client_state: e.target.value });
   }

   OnClientNoteChange = (e) => {
    this.setState({ client_note: e.target.value });
   }

   addClient = (e) => {
        e.preventDefault();
        const { client_name,client_company,client_address,client_email,client_zipcode,client_city,
            client_country,client_state,client_phone,client_note} = this.state;
        const {estimate} = this.props;
        this.setState({ submitted: true });

        if (client_name && client_name !='' && this.handleValidation()){
            const client_infos = {
                         name:trim(client_name),
                         company_name: trim(client_company),
                         phone:client_phone,
                         address: client_address,
                         zipcode: client_zipcode,
                         city: client_city,
                         country: client_country,
                         state: client_state,
                         email:client_email,
                         note:client_note,
            }
            this.props.dispatch(clientActions.add(client_infos,'/estimatedetails/'+ estimate.id));
            this.setState({ submitted: false });
        }
    }

    handleValidation(){
            const { client_email,client_zipcode,client_phone } = this.state;
    
            if (!this.handleNameValidation()){ 
                return false;
            }
    /*         if (!isNumeric(client_zipcode)){
                return false;
            } */
            if (!this.handleEmailValidation(client_email)){ 
                return false;
            }
    
            if (!this.handlePhoneValidation(client_phone)){ 
                return false;
            }
    
            return true;
        }
    
    handleNameValidation(){
            const { client_name } = this.state;
            if(client_name == ""){
                return false
            }
            return true
        }

     handleZipCodeValidation(zipcode){
    /*         if(zipcode !== ""){
                if (!isNumeric(zipcode)){
                    return false;
                }
            } */
            return true
        }
    
    handlePhoneValidation(number){
            
            if(number && number !== ""){
                return isPossiblePhoneNumber(number) //&& isValidPhoneNumber(number)
            }
    
            return true;
        }
    
    handleEmailValidation(email){
    /*         if(!email){
                return false;
            } */
    
            if(email !== ""){
                let lastAtPos = email.lastIndexOf('@');
                let lastDotPos = email.lastIndexOf('.');
          
                if (!(lastAtPos < lastDotPos 
                    && lastAtPos > 0 
                    && email.indexOf('@@') == -1 
                    && lastDotPos > 2 
                    && (email.length - lastDotPos) > 2)) {
                  return false;
                }
            }
            return true;
    }
        
    addToCartList (event,recipe) {
        event.preventDefault();
        const {intl,dispatch,estimate} = this.props;
        var found = this.state.orderList.find(x => x.item.id == recipe.id)
       
        if (!found){
             dispatch(recipesActions.addEstimateRecipe(estimate.id,
                                                      {invoicing_recipe_id: recipe.id,
                                                       quantity_value: '1',
                                                       is_nb_recipes: false}));
        }else{
            toaster_info(intl.formatMessage({ id: 'estimate.toaster.recipe.add.duplicate.message.title' }),
                         intl.formatMessage({ id: 'estimate.toaster.recipe.add.duplicate.message.content' }))
        } 
    }

    
    removeFromCartList (event,recipe) {
        event.preventDefault();
        const {orderList} = this.state;

        //Manage orders removal based on recipe name
        let updatedList = orderList.filter((item) => {
            return item.id!=recipe.id
        });

        if (updatedList.length == 0){
            this.setState({ orderList: []});  
        }
        else{
            this.setState({ orderList: updatedList});  
        }   

        if (updatedList.length==0){
            this.setState({ emptyOrders: true});
        }
    }

    assertQuantityPermittedIncluded(elem){
        let isFound = false;
        let unitPart = "";
        let inputs= elem.value.replace(/,/g, '.').toLowerCase();
        inputs = inputs.split(' ').filter(Boolean);
        console.log('assertQuantityPermittedIncluded')
        if (inputs.length > 2)
          return false;
        
        if (inputs.length === 1){
            console.log('length 1')
          if (elem.quantity_type === 'quantity'){
            if (!isNumeric(inputs)) 
                  return false;
          }else{
            console.log('not quantity')
            //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
            //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
            let tokens = inputs[0].match(/[a-z+|[^a-z]+/gi);
            console.log(tokens)
            if (tokens.length !== 2)
              return false;
            for (let i of tokens){
              if (!isNumeric(i)) unitPart = i;
            }
          }
    
        }else{
          if (inputs.length > 2)
              return false
          if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
              return false
          for (let i of inputs){
            if (!isNumeric(i)) unitPart = i;
          }
        }
    
        if (elem.quantity_type === 'volume'){
          for (let unit of unitVolume ){
            if (unitPart === unit){
                isFound = true;
                break;
            }
          }
        }else if (elem.quantity_type === 'weight'){
          for (let unit of unitWeight ){
            if (unitPart === unit){
              isFound = true;
              //console.log('isFound');
              break;
            }
         }
        }else {
            if (inputs.length > 1)
                return false
            isFound = true;
        }
        return isFound;
      }
    


    handleAllRecipesValidation(){
        const { orderList} = this.state;
        let ret = true;
        orderList.forEach((item)=>{
            item.value = trim(item.value).toLowerCase().replace(/,/g, '.')
            if ((item.value == "") || (item.value == "0")|| (item.value.length == 0) || !isNumeric(item.value)){
                ret = false;    
            }     
            if (Math.trunc(item.value).toString().length>=9){
                ret = false;
            }
        });
        return ret;
    }

/*     handleAllIngredientsValidation(){
        const {ingredQuantities} = this.state;
        let ret = true;
        console.log('handleAllIngredientsValidation')
        console.log(ingredQuantities)
        for (let elem of ingredQuantities){
            console.log('inside')
            elem.value = trim(elem.value).toLowerCase().replace(/,/g, '.');
            if ((elem.value == "") || (elem.value == "0")|| (elem.value.length == 0)){
                ret = false;    
            } 
            if (!this.assertQuantityPermittedIncluded(elem)) ret = false;

            if (Math.trunc(elem.value).toString().length>=9){
                ret = false;
            }
        } 
        return ret;
    } */

    cleanI18nDecimals(){
        const {orderList} = this.state;
        let cleanUnits=[]

          for (let u of orderList){
            cleanUnits.push({id:u.id,
                             item: u,
                             value:trim(u.value).toLowerCase().replace(/,/g, '.'),
                             quantity_type:u.quantity_type,
                             is_nb_recipes: u.is_nb_recipes,
                             unit: u.unit});
          }
          this.setState({ orderList: cleanUnits});
      }
    

/*     updateEstimate = (e) => {
        e.preventDefault();
        const { dispatch,customer,estimate} = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true))) 
        {
            dispatch(alertActions.clear());
            this.setState({ submitted: true });
    
            this.cleanI18nDecimals();
    
            if (!this.handleAllRecipesValidation()){
                const title = <FormattedMessage id="estimate.quantity.createorder.check.error.title"
                                                     defaultMessage='Quantity update error'/>
                const content =  <FormattedMessage id="estimate.quantity.createorder.check.error.message"
                                                      defaultMessage='Quantity non numeric or invalid'/>
                dispatch(alertActions.error({title:'estimate.quantity.createorder.check.error.title',
                                                 content:'estimate.quantity.createorder.check.error.message'}));   
    
                return;
            }
    
            let properties = {
                    ref_name: trim(this.state.estimateName).substring(0,80),
                    ref_number: trim(this.state.reference).substring(0,80),
                    type : 'estimate',
                    recipesUnits:this.state.orderList,
                    created: this.state.currentDate,
                    due: this.state.dueDate,
                    client_name : this.state.recipient,
                    client_email : this.state.recipient_email,
                    state: this.state.status,
                    note: this.state.note,
            }
            this.props.dispatch(recipesActions.updateEstimate(estimate.id,properties));  

        }else{
            this.setState({ activeModalPremium: true});
        }  
    }  */


    createDirectOrder = (e) => {
        e.preventDefault();
        const { dispatch,customer,estimate,intl} = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true))) 
        {
            dispatch(alertActions.clear());
            this.setState({ submitted: true });
    
     /*        this.cleanI18nDecimals();
    
            if (!this.handleAllRecipesValidation()){
                const title = <FormattedMessage id="production.quantity.createorder.check.error.title"
                                                     defaultMessage='Quantity update error'/>
                const content =  <FormattedMessage id="production.quantity.createorder.check.error.message"
                                                      defaultMessage='Quantity non numeric or invalid'/>
                dispatch(alertActions.error({title:'production.quantity.createorder.check.error.title',
                                                 content:'order.quantity.createorder.check.error.message'}));   
    
                return;
            }
    
            if (!this.handleAllIngredientsValidation()){
                const title = <FormattedMessage id="production.quantity.createorder.check.error.title"
                                                defaultMessage='Quantity update error'/>
                const content =  <FormattedMessage id="production.quantity.createorder.check.error.message"
                                                   defaultMessage='Quantity non numeric or invalid'/>
                dispatch(alertActions.error({title:'production.quantity.createorder.check.error.title',
                                             content:'order.quantity.createorder.check.error.message'}));  
    
                return;
            } */

            let properties = {
                    ref_name: trim(this.state.estimateName).substring(0,80),
                    recipesUnits:this.state.orderList,
                    prod_date: this.state.dueDate,
                    is_prod: true,
            }
            this.setState({ status: 'production' });
            this.props.dispatch(recipesActions.saveOrder(properties,true,true));  
            toaster_info(intl.formatMessage({ id: 'estimate.toaster.production.started.message.title' }),
                         intl.formatMessage({ id: 'estimate.toaster.production.started.message.content' }))

            let updateProps = {
                ref_name: trim(this.state.estimateName).substring(0,80),
                ref_number: trim(this.state.reference).substring(0,80),
                type : 'estimate',
                recipesUnits:this.state.orderList,
                created: this.state.currentDate,
                due: this.state.dueDate,
                client_name : this.state.recipient,
                client_email : this.state.recipient_email,
                state: 'production',
                note: this.state.note,
            }
            this.props.dispatch(recipesActions.updateEstimate(estimate.id,updateProps,true));  
            

        }else{
            this.setState({ activeModalPremium: true});
        }  
    
    } 


    createBillEstimate = (e) => {
        e.preventDefault();
        const { dispatch,customer,estimate,intl} = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true))) 
        {
            dispatch(alertActions.clear());
            this.setState({ submitted: true });

            this.props.dispatch(recipesActions.billEstimate(estimate.id));      
            toaster_info(intl.formatMessage({ id: 'estimate.toaster.billing.started.message.title' }),
                         intl.formatMessage({ id: 'estimate.toaster.billing.started.message.content' }))       

        }else{
            this.setState({ activeModalPremium: true});
        }  
    
    } 

    generateDocBackend(event, sendEmail){
        event.preventDefault();
        const { orderList } = this.state;
        const {customer,estimate,intl} = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }
    
        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (membership.membership_type=="Professional")|| (user.bypass==true))) 
        {
            const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
            //var offset = new Date().getTimezoneOffset();
            //console.log(tzid);
            //console.log(offset);
            if (orderList){
                let properties = {
                    id: estimate.id,
                    items: orderList,
                    //name: estimate.ref_name,
                    timezone: tzid,
                    number:estimate.ref_number
                }
                if (sendEmail){
                    this.props.dispatch(recipesActions.sendEstimate(properties));
                    toaster_info(intl.formatMessage({ id: 'estimate.toaster.pdf.send.message.title' }),
                                 intl.formatMessage({ id: 'estimate.toaster.pdf.send.message.content' }))    
                }else{
                    this.props.dispatch(recipesActions.renderEstimate(properties)); 
                    toaster_info(intl.formatMessage({ id: 'downloadrecipe.toaster.message.title' }),
                                 intl.formatMessage({ id: 'downloadrecipe.toaster.message.content' }))   
                }
            } 
        }else{
           this.setState({ activeModalPremium: true});
        }  
    }


    filterNameList(event){
        event.preventDefault();
        const {availableRecipesList} = this.state;
        this.setState({nameSelected: event.target.value});
        this.setState({categorySelected: ""});

        let updatedList = availableRecipesList.filter((item) => {
                return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                            .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });
        this.setState({newItems: updatedList});
        this.setState({filtering: true});
    }

    filterCategoryList(event,data){
        event.preventDefault();
        const {categoriesRecipe} = this.props;
        const {availableRecipesList} = this.state;

        this.setState({filtering: false});
        this.setState({categorySelected: ""});
        this.setState({nameSelected: ""});

        if (data.value!="")
        {
            let category_id = data.value;
            let elem = categoriesRecipe.options.find(category => category.value == category_id)

            if (elem){
                let updatedList = availableRecipesList.filter((item) => {
                    if (item.category && item.category.id){
                        return item.category.id == elem.value
                    }
                    return null;
                });
                this.setState({newItems: updatedList});
                this.setState({filtering: true});
                this.setState({categorySelected: {id:category_id,
                                                  name:elem.text}});
            }
        }
    }

    onTypeQuantityChange = (event,data,id) => {
        event.preventDefault();

        let ordersList = this.state.orderList.map((item) => {
                    if (item.id == id){
                        if (data.value == 'recipe') {
                           item.is_nb_recipes = true
                        }else{
                           item.is_nb_recipes = false
                        }
                    }
                    return item;
            }); 
        this.setState({ orderList: ordersList});
    }

    onTypeQuantityValue = (id,type) => {
        const {intl} = this.props;
        let toReturn = 'unit' 
        let found
   
        if (type =='recipe'){
            found = this.state.orderList.find(unit => unit.id == id)
            if (found){
                if (found.is_nb_recipes) {
                    toReturn = 'recipe'
                 }else{
                    toReturn = found.unit
                 }
            } 
        }/* else{
            found = this.state.ingredQuantities.find(unit => unit.id == id)
            if (found){
                    if (found.unit== '') {
                        toReturn = intl.formatMessage({ id: 'estimate.quantity.unit.plural.text' }) 
                     }else{
                        toReturn = found.unit
                     }
            } 
        } */
        return toReturn
    }

    onInputQuantityValue = (id,type) => {
        let toReturn = '1' 
        let found
        if ( type =='recipe'){
            found = this.state.orderList.find(unit => unit.id == id)
            if (found){
                toReturn = optimizeDecimalPart(Number(found.value))
            } 
        }/* else{
            found = this.state.ingredQuantities.find(unit => unit.id == id)
            if (found){
                toReturn = found.value
            } 
        } */
        return toReturn
    }

    onInputQuantityChange = (event,id) => {
        event.preventDefault();

        let ordersList = this.state.orderList.map((item) => {
                    if (item.id == id){
                        //let input  = event.target.value;
                        //input = Number(trim(input.toString()).replace(/,/g, '.')).toFixed(3);
                        item.value = event.target.value
                    }
                    return item;
        }); 
        this.setState({ orderList: ordersList});
    }

    editQuantityHandlerInput (event,index,i) {
        event.preventDefault();
        let quantity = Number(i.value)
        this.setState({ newQuantity: quantity});
        this.setState({ editCell: true});
        this.setState({ editCellIndex: index});
    }; 

    onNewQuantity = (e,item) => {
        //e.preventDefault();
        this.setState({ newQuantity: e.target.value});
    }

    assertQuantityField(quantity){
        if ((isFloat(quantity) || isNumeric(quantity))
          && (quantity != "")
          && Math.trunc(quantity).toString().length<10)
          return true;
        return false;
    }

    getRelevantOptions = (unit,type,purchase_pack) => {
        const {intl} = this.props;
        let options = []
        let emptyUnit = intl.formatMessage({ id: 'estimate.quantity.unit.plural.text' }) 
        
        unit =  unit.toLowerCase()
        if (type == 'recipe'){
            options= [{key:unit,text:unit,value:unit},
                      {key:'recipe',text:<FormattedMessage id="estimate.quantity.recipe.text"
                                                           defaultMessage='recipe(s)'/>,value:'recipe'}] 
        }else{
            if (isWeight(unit)){
                 if (mgrams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value :pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'},
                              {key:'kg',text:'kg',value:'kg'}]
                 }else if (grams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'kg',text:'kg',value:'kg'}]
                }else if (kgrams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'}]
                 }
                 else{
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit}]
                 }   
            }
            else if (isVolume(unit)){
                if (cliters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'ml',text:'ml',value:'ml'},
                              {key:'l',text:'l',value:'l'}]
                 }
                 else if (mliters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'l',text:'l',value:'l'}]
                 }else if (liters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'ml',text:'ml',value:'ml'}]
                 }
                 else{
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit}]
                 }     

            }else{
                options= [{key:pack_unit_key_reserved,text: purchase_pack + ' ' + emptyUnit,value:pack_unit_key_reserved},
                          {key:emptyUnit,text:emptyUnit,value:emptyUnit}]
            }
        }
        return options;
    }



    updateQuantityOnBlur (event,estimate_id,breakdown) {
        event.preventDefault();
        const {newQuantity,active_role} = this.state;
        const {dispatch} = this.props;
        let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
        let quantity_origin = breakdown.value;
        quantity_origin = Number(quantity_origin).toFixed(3) 
        const title = <FormattedMessage id="estimate.quantity.check.error.title"
                                        defaultMessage='Quantity Update Error'/>

        if (this.assertQuantityField(quantity) & quantity!=quantity_origin){

            if (active_role!= GUEST){
                dispatch(recipesActions.updateEstimateRecipeQuantity(estimate_id,{invoicing_recipe_id:breakdown.item.id,
                                                                                  quantity_value:quantity,
                                                                                  is_nb_recipes:breakdown.is_nb_recipes}));
                breakdown.unit = quantity
                this.setState({ editCell: false });
                //this.setState({ newUnit: '' });
            }else{
                const content =  <FormattedMessage id="estimate.quantity.privilege.error.message"
                                                   defaultMessage='You do not have Right Privilege'/>
                dispatch(alertActions.error({title:'estimate.quantity.privilege.error.message',
                                             content:'estimate.quantity.check.error.title'}));  
                this.setState({ editCell: false });
            }
        }else{
            if (Math.trunc(quantity).toString().length>=9){

                const content =  <FormattedMessage id="estimate.quantity.check.error.message"
                                                  defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                 dispatch(alertActions.error({title:'estimate.quantity.check.error.title',
                                             content:'estimate.quantity.check.error.message'}));   
             }
            this.setState({ editCell: false });
        }
    }


    updateQuantityOnEnter (event,estimate_id,breakdown) {
        const {newQuantity,active_role} = this.state;
        const {dispatch} = this.props;

        if (event.key === 'Enter') {
                let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
                let quantity_origin = breakdown.value;
                quantity_origin = Number(quantity_origin).toFixed(3)
                const title = <FormattedMessage id="estimate.quantity.check.error.title"
                                                defaultMessage='Quantity Update Error'/>

                if (this.assertQuantityField(quantity) && quantity!=quantity_origin){

                    if (active_role!= GUEST){
                        dispatch(recipesActions.updateEstimateRecipeQuantity(estimate_id,{invoicing_recipe_id:breakdown.item.id,
                                                                                      quantity_value:quantity,
                                                                                      is_nb_recipes:breakdown.is_nb_recipes}));
                        breakdown.unit = quantity
                        this.setState({ editCell: false });
                    }else{
                        const content =  <FormattedMessage id="estimate.quantity.privilege.error.message"
                                                           defaultMessage='You do not have Right Privilege'/>
                        dispatch(alertActions.error({title:'estimate.quantity.privilege.error.message',
                                                     content:'estimate.quantity.check.error.title'}));  
                        this.setState({ editCell: false });
                    }
                }else{
                    if (Math.trunc(quantity).toString().length>=9){
                       const content =  <FormattedMessage id="estimate.quantity.check.error.message"
                                                         defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                        dispatch(alertActions.error({title:'estimate.quantity.check.error.title',
                                                    content:'estimate.quantity.check.error.message'}));   
                    }
                    this.setState({ editCell: false });
                }
        }
    }


    computeSellingNoVAT(e){
        return (Number(e.item.selling_price)/Number(e.item.quantity_parts)) / (1 + Number(e.item.selling_vat))
    }


    addOrdersRows(estimates){
        const {currency,submitted,editCell,editCellIndex,newQuantity} = this.state;
        const {estimate} = this.props;
        return estimates.map((e,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell  width={4}>
                        {e.item.category && 
                                     <span style={{'color':'#b5b1b1','font-size':'11px'}}><i>{' '}{e.item.category.name}{''}</i></span>}   
                            <div>         
                            <Icon name='food' color='blue'/>
                            <a href="#" 
                               onClick={(event) => this.seeRecipe(event,e.id)}
                               style={{'color': 'black'}}>
                               {e.item.name && e.item.name.length >= 65 && 
                                  e.item.name.substring(0,65) + '..'}
                                    {e.item.name && e.item.name.length < 65 && 
                                    e.item.name}
                            </a> </div>  
                            {/* {e.item.category && <p style={{'color':'#b5b1b1',marginLeft:'3em',fontSize:'11px'}}><i>{e.item.category.name}</i></p>} */}
                        </Table.Cell>
{/*                      <Table.Cell width={3} textAlign='right' >
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Input key={index} 
                                                        type="text"
                                                        id="quantity"
                                                        value ={this.onInputQuantityValue(recipe.id,'recipe')}
                                                        onChange={(event) => this.onInputQuantityChange(event,recipe.id)}
                                                        autoFocus
                                                        style={{height:'2.5em',minWidth:'5em',maxWidth:'8em',padding:'0em 0.5em 0em 0.5em'}}
                                                />  
                                            <Form.Dropdown key={index} 
                                                        options={this.getRelevantOptions(recipe.yield_units,'recipe',"")}
                                                        onChange={(event,data)=>this.onTypeQuantityChange(event,data,recipe.id)}
                                                        value ={this.onTypeQuantityValue(recipe.id,'recipe')}
                                                        style={styles.ButtonGreenNoMarginOrder}
                                                />
                                    </Form.Group>
                                </Form>
                        </Table.Cell> */}
                        { e.is_nb_recipes &&
                                <Table.Cell width={3} textAlign='center' onClick={(event) => this.editQuantityHandlerInput(event,index,e)}>
                                    {(!editCell || (editCell && index != editCellIndex) ) &&
                                            <a href='#'> <FormattedNumber value={Number(e.value)} maximumFractionDigits="3" />{' '} <FormattedMessage id="estimate.quantity.recipe.text"
                                                                                        defaultMessage='recipe(s)'/></a>}
                                    {editCell && index == editCellIndex &&
                                    <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCell: false})}}>
                                            <div>     
                                                    <Input type="text"
                                                            id="quantity"
                                                            value={newQuantity}
                                                            onChange={this.onNewQuantity}
                                                            onBlur={(event) => this.updateQuantityOnBlur(event,estimate.id,e)} 
                                                            onKeyPress={(event) => this.updateQuantityOnEnter(event,estimate.id,e)} 
                                                            autoFocus
                                                            style={{height:'2.6em',minWidth:'6em',maxWidth:'6em', padding:'0em 0.3em 0em 0.3em'}}
                                                            label={{ basic: false, content: <FormattedMessage id="estimate.quantity.recipe.text"
                                                                                                              defaultMessage='recipe(s)'/>   }}
                                                            labelPosition='right'
                                                            //error={submitted && (!this.assertQuantityField())}
                                                    />
                                            </div>
                                        </OutsideClickHandler>}  
                             </Table.Cell>}
                        { !e.is_nb_recipes &&
                                <Table.Cell width={4} textAlign='center' onClick={(event) => this.editQuantityHandlerInput(event,index,e)}>
                                {(!editCell || (editCell && index != editCellIndex) ) &&
                                        <a href='#'>  <FormattedNumber value={Number(e.value)} maximumFractionDigits="3"/>{' '}{e.item.yield_units}</a>}
                                {editCell && index == editCellIndex &&
                                <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCell: false})}}>
                                        <div>     
                                                <Input type="text"
                                                        id="quantity"
                                                        value={newQuantity}
                                                        onChange={this.onNewQuantity}
                                                        onBlur={(event) => this.updateQuantityOnBlur(event,estimate.id,e)} 
                                                        onKeyPress={(event) => this.updateQuantityOnEnter(event,estimate.id,e)} 
                                                        autoFocus
                                                        style={{height:'2.6em',minWidth:'6em',maxWidth:'6em', padding:'0em 0.3em 0em 0.3em'}}
                                                        label={{ basic: false, content:e.item.yield_units  }}
                                                            labelPosition='right'
                                                        //error={submitted && (!this.assertQuantityField())}
                                                />
                                        </div>
                                    </OutsideClickHandler>}  
                         </Table.Cell>}
                        <Table.Cell width={2} textAlign='right'><FormattedNumber value={e.item.yield_count} /> {e.item.yield_units}</Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                           {typeYield(e.item.yield_units) == 'quantity' &&
                            <span>
                              <FormattedNumber value={e.item.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}{e.item.yield_units}</span>
                          }
                         {typeYield(e.item.yield_units) != 'quantity' &&
                            <span>
                              <FormattedNumber value={e.item.cost_total/Number(e.item.yield_count)} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}{e.item.yield_units}{/* <FormattedMessage id="estimate.quantity.recipe.singular.text"
                                                           defaultMessage='recipe'/> */}</span>
                            }
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                           {typeYield(e.item.yield_units) == 'quantity' &&
                            <span>
                              <FormattedNumber value={this.computeSellingNoVAT(e)} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}{e.item.yield_units}</span>
                             }
                           {typeYield(e.item.yield_units) != 'quantity' &&
                            <span>
                               <FormattedNumber value={(this.computeSellingNoVAT(e)/Number(e.item.yield_count))} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}{e.item.yield_units}{/* <FormattedMessage id="estimate.quantity.recipe.singular.text"
                                                           defaultMessage='recipe'/> */}</span>
                            }
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='center'>
                        { !e.is_nb_recipes && typeYield(e.item.yield_units) == 'quantity' &&
                            <FormattedNumber value={this.computeSellingNoVAT(e) * Number(e.value)} style="currency" aximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>}
                        { !e.is_nb_recipes && typeYield(e.item.yield_units) != 'quantity' &&
                            <FormattedNumber value={(this.computeSellingNoVAT(e)/Number(e.item.yield_count)) * Number(e.value)} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>}
                       
                        { e.is_nb_recipes &&
                            <FormattedNumber value={(this.computeSellingNoVAT(e)) * Number(e.value) * Number(e.item.yield_count)} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>}
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                             {Number(e.item.selling_vat) > 0 &&
                             <FormattedNumber value={e.item.selling_vat} style="percent" maximumFractionDigits="2"/>}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                                        <Popup trigger={<Icon color='grey' name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                                                onClick={(event) => this.deleteRecipeClickHandler(event,index,e)}/>}
                                                content={<FormattedMessage id="estimate.popup.delete.text"
                                                                        defaultMessage='Delete'/>}
                                                inverted
                                                size='small'/>
                        </Table.Cell>
                        <Modal id={index}
                                   open={this.state.activeModalRecipeDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   onClose={this.hideModal}
                                   style={styles.Modal}
                                   closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="estimate.modal.order.deletion.title"
                                                                                                    defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p><FormattedMessage id="estimate.modal.order.deletion.message"
                                                         defaultMessage='Really sure to delete {estimate} from estimate ?'
                                                         values={{estimate: e.item.name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                       <Button style={styles.ButtonGrey}
                                               onClick={this.hideModal}>
                                            <FormattedMessage id="estimate.modal.order.deletion.button.no"
                                                              defaultMessage='cancel'/>
                                        </Button>
                                        <Button style={styles.ButtonRed}
                                                labelPosition='right' 
                                                content={<FormattedMessage id="estimate.modal.order.deletion.button.yes"
                                                                          defaultMessage='confirm'/> }
                                                icon='checkmark' 
                                                onClick={(event) =>this.deleteRecipeEstimate(event,estimate.id)}/>
                         </Modal.Actions>
                        </Modal> 
                     </Table.Row>
                );
        });
    }
    
    addRecipesRows(recipes){
        return recipes.map((recipe,index) => {
                return (
                    <Table.Row key={index} style={{'backgroundColor':'#fcfcfc'}}
                                    /* onClick={(event)=>this.addToCartList(event,recipe)} */>
                        <Table.Cell width={5}>
                            <Icon name='food' color='blue'/>
                            <a //href="#" 
                               //onClick={(event) => this.seeRecipe(event,recipe.id)}
                               style={{'color': 'black',fontWeight:'bolder'}}>
                                  {recipe.name.length >= 65 && 
                                    recipe.name.substring(0,65) + '..'}
                                    {recipe.name.length < 65 && 
                                    recipe.name}
                                   {/* {recipe.name.substr(0, 60)} */}
                            </a>
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                                   <Button color='blue' circular className="ui small icon button" 
                                             onClick={(event)=>this.addToCartList(event,recipe)}>
                                            <i className="add icon"></i>
                                   </Button>
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    toggleItemsMenu(e,type){
        this.setState({sidebarToggled: !this.state.sidebarToggled});
        //this.setState({toggled: false});
        this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({categorySelected: ""});
    } 


    resetOrder(event){
        this.setState({ emptyOrders: true });
        this.setState({ orderList: []});  
        this.setState({ units: []}); 
        //this.setState({ ingredQuantities: []});   
    }

    openPlanning = (e) => {
        e.preventDefault();
        history.push('/productions',{planning:true});
    }

    hideModal() {
        this.setState({ activeModalPremium: false });
        this.setState({ activeModalRecipeDelete: false });
        this.setState({ activeModalNewClient: false });
    }


    currencySymbol(){    
        const {currency} = this.state;
        const {intl} = this.props;
        let output_currency_symbol = ''
    
        if (currency == 'None') 
            return output_currency_symbol
    
        let value = Intl.NumberFormat(intl.locale,{
                                                  style:'currency',
                                                  currency: currency,
                                                  currencyDisplay: 'symbol',
                                      }).format(0)
        value = value.replace(/,/g, '.');
        value +='' 
        value = value.replace('.','')
    
        let regexp = /\D+/g;
        let symbol = [...value.matchAll(regexp)];      
        
        if (symbol && symbol.length > 0)
          output_currency_symbol = symbol[0][0]
        
        return ''+output_currency_symbol
      }

    render() { 
        const { categoriesRecipe,alert,dispatch,loading_r,loading_cr,intl,adding,loading,customer,estimate,client } = this.props;
        const { newItems,filtering,nameSelected,categorySelected,emptyOrders,orderList,availableRecipesList,
            isMobile,sidebarToggled,currentDate,dueDate,estimateName,reference,status,currency,note,client_address,client_city,client_company,client_country,client_email,
             client_name,client_note,client_phone,client_state,client_zipcode,client_selected,submitted } = this.state;
        let isLimited = this.isUnSubscribedUser()
        let items = [];
        let membership = null;
        let user = null;

        if (customer){
             membership = customer.membership;
             user = customer.user;
        }

		let className = "floating-menu";

        if (filtering){
                items = newItems
        }else{
                items = availableRecipesList
        }

        //Filter and notify restrictions if user unsubscribed
        if (isLimited && items){
                items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        }

/*         //Filter and notify restrictions if user unsubscribed
        if (this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        } */

        return ( 
                <div>
                    <Sidebar as={Segment}
                             animation={'overlay'}
                             direction={'right'}
                             visible={sidebarToggled}
                             vertical
                             width= {isMobile?'wide':'very wide'}
                             icon='labeled'
                             style={{'backgroundColor':'#fcfcfc'/*,'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'*/}}
                             >
                            <Grid textAlign='center' /*style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'}}*/>
                                <Grid.Row columns={1}>
                                    <Grid.Column /*mobile={16} tablet={12} computer={8}*/ fluid>
                                      <div style={{display: 'inline-block;',position:'absolute'}}>
                                                <Icon name='close' size='large' color='grey'  onClick={(e) => this.toggleItemsMenu(e)}/>
                                      </div>  
                                      <Form>
                                      <Form.Field align='left' style={{'margin':'2em 0em 1em 1em'}}>
                                         <Header as='h2' style={{'marginLeft': '1em'}}>
                                            <img src={img_pan} style={{width:'50px'}}/>
                                            <Header.Content>
                                            <FormattedMessage id="estimate.form.input.recipe.title"
                                                                  defaultMessage='Add recipes'/>
                                            </Header.Content>
                                        </Header>
                                       </Form.Field>
                                       <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                             <FormattedMessage id="estimate.form.input.search.placeholder"
                                                            defaultMessage="Enter a name ...">
                                                {placeholder => 
                                                        <Input
                                                            fluid
                                                            onChange={this.filterNameList}
                                                            color='teal'
                                                            size='large'
                                                            icon='filter'
                                                            placeholder={placeholder}
                                                            value={nameSelected}
                                                            disabled = {isLimited}
                                                            style={{'backgroundColor':'#f5f5f5'}}
                                                            //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />
                                                } 
                                                </FormattedMessage> 
                                        </Form.Field>
                                       <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                                <span>
                                                    <p style={styles.FontLato13}>
                                                        <FormattedMessage id="estimate.form.filter.category.title"
                                                                        defaultMessage='Filter by category' />
                                                    </p>
                                                    <FormattedMessage id="estimate.form.filter.category.placeholder"
                                                                        defaultMessage='Select a category'>
                                                        {placeholder =>   
                                                            <Dropdown selection
                                                            options= {categoriesRecipe && categoriesRecipe.options} 
                                                            value= {categorySelected && categorySelected.id}
                                                            placeholder={placeholder} 
                                                            onChange={this.filterCategoryList}
                                                            clearable
                                                            fluid
                                                            disabled = {isLimited}
                                                            //style={{minWidth:'18em',maxWidth:'26em'}}
                                                            />}   
                                                    </FormattedMessage>
                                                </span>
                                        </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                   { (loading_r || loading_cr || adding) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Grid.Column /*mobile={16} tablet={16} computer={16}*/ fluid >
                                        <Table  //selectable 
                                                unstackable 
                                                //compact 
                                                //definition
                                                //striped 
                                                singleLine 
                                                style={styles.FontLato13Border}
                                                //style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-none;','-ms-overflow-style':'none;'}}
                                                >
                                                <Table.Body>
                                                {availableRecipesList && availableRecipesList.length > 0 && items &&
                                                    this.addRecipesRows(items)
                                                }
                                                {availableRecipesList && availableRecipesList.length == 0 &&
                                                <Table.Row textAlign='left' style={{'backgroundColor':'#fcfcfc','font-family': 'Lato','font-size': '14px'}}>
                                                    <Table.Cell>
                                                        <FormattedMessage id="estimate.list.recipe.empty.message"
                                                                            defaultMessage='No recipe created yet' />
                                                    </Table.Cell>
                                                    <Table.Cell width={1} textAlign='right'>
                                                        <Button color='teal' circular  className="ui small icon button" 
                                                                onClick={this.createRecipe}>
                                                                <i className="add icon"></i>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                                }
                                            </Table.Body>
                                            {isLimited && items &&
                                                <Table.Footer /*fullWidth*/ width={4}>
                                                        <Table.HeaderCell colSpan='2'>
                                                        <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                                <Image src={img_performance} size ='tiny' rounded/>
                                                                <br/>
                                                                <Message.Content style={{marginLeft:'1em'}}>
                                                                    <Message.Header color='black'>
                                                                        <FormattedMessage id="estimate.list.premium.title.message.upgradeplan"
                                                                                            defaultMessage='Recipes/Ingredients limit exceeded'/> 
                                                                    </Message.Header>
                                                                    <br/>
                                                                    <div style={styles.FontLato13}>
                                                                        <FormattedMessage id="estimate.list.premium.message.upgradeplan.line1"
                                                                                        defaultMessage='Your have'/>{' '}{availableRecipesList.length}{' '}
                                                                        <FormattedMessage id="estimate.list.premium.message.upgradeplan.line2"
                                                                                        defaultMessage='recipes but limited to'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 
                                                                    </div>
                                                                    <br/><br/>
                                                                    <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                        <FormattedMessage id="estimate.list.premium.button.chooseplan"
                                                                                    defaultMessage='Choose premium plan'/>
                                                                    </Button>                                      
                                                                </Message.Content>
                                                            </Message>
                                                        </Table.HeaderCell>
                                            </Table.Footer>}
                                    </Table>
                              </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Sidebar>
                     <SideLeftUncoverProductions>
                        <Grid stackable  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                              <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="estimate.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/productions/'}><FormattedMessage id="estimate.link.productions"
                                                              defaultMessage='Productions'/></Breadcrumb.Section>                             
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/estimates/'}><FormattedMessage id="estimate.link.estimates"
                                                              defaultMessage='Estimates'/></Breadcrumb.Section>                             
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active>{estimate && estimate.ref_name}</Breadcrumb.Section>    
                                {/* <Breadcrumb.Section active><FormattedMessage id="estimate.link.edit"
                                                              defaultMessage='Edit Estimate'/></Breadcrumb.Section> */}
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    <Header as='h2'>
                                        <img src={img_invoicing} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="estimate.page.title"
                                                              defaultMessage='Edit'/>
                                            <Header.Subheader><FormattedMessage id="estimate.header.caption.title"
                                                                    defaultMessage='Update my estimate' />
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                    </Grid.Column >
                            </Grid.Row> 
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row columns={3}>
                              <span style={{fontSize: '14px', fontColor:'black', paddingLeft:'2em'}}>
                                      <FormattedMessage id="estimate.document.estimate.number"
                                                        defaultMessage='N° '/> {' '} {reference}
                                    </span>
                                <Table  stackable
                                        style={{'background-color':'#fcfcfc', 'border-style':'none',paddingLeft:'2em'}}>
                                    <Table.Body> 
                                    <Table.Row>
                                             <Table.Cell width={3} textAlign='left' >
                                                <Form /*  style={{paddingTop:'0em'}}  */ >
                                                {loading &&
                                                <Dimmer active inverted>
                                                    <Loader inverted content='Loading' />
                                                </Dimmer> }
                                                <label><FormattedMessage id="estimate.form.input.creation.date.title"
                                                                         defaultMessage='Creation date'/></label>
                                                <DatePicker  showIcon
                                                               selected={currentDate}
                                                                onChange={(date)=>this.setCurrentDate(date)}
                                                                 locale={intl.locale}
                                                                 showWeekNumbers
                                                                 dateFormat="P"
                                                    />
                                                <label style={{paddingTop:'1em'}} ><FormattedMessage id="estimate.form.input.due.date.title"
                                                                         defaultMessage='Due date'/></label>
                                                    <DatePicker  showIcon
                                                                 selected={dueDate}
                                                                 onChange={(date)=>this.setDueDate(date)}
                                                                 locale={intl.locale}
                                                                 showWeekNumbers
                                                                 dateFormat="P"
                                                     />
                                                 <label style={{paddingTop:'1em'}} ><FormattedMessage id="estimate.form.input.status.title"
                                                                         defaultMessage='Status'/></label>
                                                 <FormattedMessage id="estimate.form.input.status.placeholder"
                                                                   defaultMessage='Status'>
                                                    {placeholder =>   
                                                            <Form.Select 
                                                                    options={optionsStatus}
                                                                    placeholder={placeholder} 
                                                                    value={status}
                                                                    onChange={this.onStatusChange}
                                                                    style={{'background-color':'#ebf2f5','color': 'black'}}
                                                    />}
                                                </FormattedMessage>  
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell width={3} textAlign='left' >
                                              <Form  /* style={{paddingTop:'0em'}}  */ >
                                              {loading &&
                                                <Dimmer active inverted>
                                                    <Loader inverted content='Loading' />
                                                </Dimmer> }
                                                 <label><FormattedMessage id="estimate.form.input.name.title"
                                                                         defaultMessage='Name of estimate'/></label>
                                                  <FormattedMessage id="estimate.form.input.name.placeholder"
                                                                   defaultMessage='XXXXXX_12345'>
                                                    {placeholder =>     
                                                        <Form.Input
                                                            type="text"
                                                            placeholder={placeholder}
                                                            id="reference"
                                                            value={estimateName}
                                                            onChange={this.onNameChange}
                                                            //style={{minWidth:'10em',maxWidth:'30em'}}
                                                           // onFocus={(e)=>this.onFocusElsewhere(e)} 
                                                           // error={submitted && !this.assertNameField()}
                                                            />}
                                                  </FormattedMessage>
                                                <Form.Group style={{paddingTop:'0.2em'}}>  
                                                <Form.Field>
                                                <label><FormattedMessage id="estimate.form.input.client"
                                                                            defaultMessage='Client, customer ...'/>
                                                </label>
                                                <Button as='div' labelPosition='left' >
                                                <FormattedMessage id="estimate.form.input.client.placeholder"
                                                                    defaultMessage='Select'>
                                                        {placeholder =>   
                                                                <Form.Select search 
                                                                        options={client && client.options}
                                                                        value = {client_selected} 
                                                                        placeholder={placeholder} 
                                                                        onChange={this.onClientChange}
                                                                        clearable
                                                                        //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                                />}
                                                </FormattedMessage>
                                                <button style={{border:'none',backgroundColor:'white',background:'none',outline:'none;'}}
                                                        onClick={(event) => this.addClientClickHandler(event)}> 
                                                   <Icon name='user add' size='large' color='blue' style={{marginLeft:'1.1em'}}  /></button>
                                                <button style={{border:'none',backgroundColor:'white',background:'none',outline:'none;'}}
                                                            onClick={(event) => this.editClientsListHandler(event)}> 
                                                       <Icon name='users' size='large' color='grey' style={{marginLeft:'0.4em'}} /></button>
                                                </Button>    
                                                </Form.Field> 
                                                <Form.Field>
                                                <Modal open={this.state.activeModalNewClient === true}
                                                       dimmer='default' 
                                                       size='small'
                                                       style={styles.Modal} 
                                                       onClose={this.hideModal}>
                                                       <Header icon='user add' content={<FormattedMessage id="estimate.modal.create.title"
                                                                                                                                defaultMessage='Add client, customer, lead ...'/>} />
                                                                        <Modal.Content>
                                                                                        <Form style={{paddingLeft:'0em'}}>
                                                                                                <Form.Field width='six'> 
                                                                                                    <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.name"
                                                                                                                            defaultMessage='Name'/></label>
                                                                                                    <FormattedMessage id="estimate.modal.form.input.name.placeholder"
                                                                                                                    defaultMessage='name'>
                                                                                                        {placeholder =>                                                                                
                                                                                                                    <Form.Input type="text" 
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="name" 
                                                                                                                        icon='user add'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_name}
                                                                                                                        onChange={this.OnNameChange}
                                                                                                                        error= {submitted && !this.handleNameValidation()}
                                                                                                                        />}
                                                                                                    </FormattedMessage>
                                                                                                </Form.Field>
                                                                                                <Form.Group widths='equal'>
                                                                                                    <Form.Field >
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.company"
                                                                                                                                defaultMessage='Company'/></label>
                                                                                                    <FormattedMessage id="estimate.modal.form.input.contact.placeholder"
                                                                                                                        defaultMessage='Company'>
                                                                                                            {placeholder =>                                                                                       
                                                                                                                    <Form.Input type="text" 
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="company" 
                                                                                                                        icon='user'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_company}
                                                                                                                        onChange={this.OnCompanyChange}/>}
                                                                                                        </FormattedMessage>
                                                                                                    </Form.Field>
                                                                                                    <Form.Field>
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.email"
                                                                                                                                defaultMessage='Email'/></label>
                                                                                                    <FormattedMessage id="estimate.modal.form.input.email.placeholder"
                                                                                                                        defaultMessage='Email'>
                                                                                                            {placeholder =>                                                                                       
                                                                                                                    <Form.Input  
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        icon='mail'
                                                                                                                        iconPosition='left'
                                                                                                                        type="email"
                                                                                                                        id="email" 
                                                                                                                        value={client_email}
                                                                                                                        onChange={this.OnEmailChange}
                                                                                                                        error={submitted && !this.handleEmailValidation(client_email)}
                                                                                                                        />}
                                                                                                        </FormattedMessage>
{/*                                                                                                         <label style={{color:'teal', }}>
                                                                                                                <FormattedMessage id="estimate.modal.form.input.email.info.message"
                                                                                                                                defaultMessage='Fill if you need to send directly your orders to your supplier'/>
                                                                                                        </label>  */}
                                                {/*                                                         {submitted && !this.handleEmailValidation(email)  &&
                                                                                                            <label style={{color:'red', }}><i>
                                                                                                                <FormattedMessage id="suppliers.modal.form.input.email.error.message"
                                                                                                                                    defaultMessage='email is used to send your orders'/>
                                                                                                            </i></label> 
                                                                                                        } */}
                                                                                                    </Form.Field>
                                                                                                </Form.Group>
                                                                                                <br/>
                                                                                                <Form.Field>
                                                                                                    <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.address"
                                                                                                                            defaultMessage='Billing Address'/></label>
                                                                                                <FormattedMessage id="estimate.modal.form.input.address.placeholder"
                                                                                                                    defaultMessage='address'>
                                                                                                        {placeholder =>          
                                                                                                                <Form.Input type="text" 
                                                                                                                    placeholder={placeholder}
                                                                                                                    id="address" 
                                                                                                                    icon='address card'
                                                                                                                    iconPosition='left'
                                                                                                                    value={client_address}
                                                                                                                    onChange={this.OnAdressChange}/>}
                                                                                                    </FormattedMessage>
                                                                                                </Form.Field>
                                                                                                <Form.Group widths='equal'>
                                                                                                    <Form.Field>
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.city"
                                                                                                                                defaultMessage='City'/></label>
                                                                                                    <FormattedMessage id="estimate.modal.form.input.city.placeholder"
                                                                                                                        defaultMessage='city'>
                                                                                                            {placeholder =>          
                                                                                                                    <Form.Input type="text" 
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="city" 
                                                                                                                        icon='map'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_city}
                                                                                                                        onChange={this.OnCityChange}/>}
                                                                                                        </FormattedMessage>
                                                                                                    </Form.Field>
                                                                                                    <Form.Field>
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.zipcode"
                                                                                                                                defaultMessage='Zipcode'/></label>
                                                                                                    <FormattedMessage id="estimate.modal.form.input.zipcode.placeholder"
                                                                                                                        defaultMessage='zipcode'>
                                                                                                            {placeholder =>          
                                                                                                                    <Form.Input type="text" 
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="zipcode" 
                                                                                                                        icon='map'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_zipcode}
                                                                                                                        onChange={this.OnZipcodeChange}
                                                                                                                        //error={submitted && !this.handleZipCodeValidation(zipcode)}
                                                                                                                        />}
                                                                                                        </FormattedMessage>
                                                                                                    </Form.Field>
                                                                                                    <Form.Field>
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.state"
                                                                                                                                defaultMessage='State'/></label>
                                                                                                    <FormattedMessage id="estimate.modal.form.input.state.placeholder"
                                                                                                                        defaultMessage='state'>
                                                                                                            {placeholder =>          
                                                                                                                    <Form.Input type="text" 
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="state" 
                                                                                                                        icon='map'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_state}
                                                                                                                        onChange={this.OnStateChange}/>}
                                                                                                        </FormattedMessage>
                                                                                                    </Form.Field>
                                                                                                </Form.Group>
                                                                                                <Form.Group widths='equal'>
                                                                                                <Form.Field >
                                                                                                    <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.country"
                                                                                                                            defaultMessage='Country'/></label>
                                                                                                <FormattedMessage id="estimate.modal.form.input.country.placeholder"
                                                                                                                    defaultMessage='country'>
                                                                                                        {placeholder =>          
                                                                                                                <Form.Input type="text" 
                                                                                                                    placeholder={placeholder}
                                                                                                                    id="country" 
                                                                                                                    icon='world'
                                                                                                                    iconPosition='left'
                                                                                                                    value={client_country}
                                                                                                                    onChange={this.OnCountryChange}/>}
                                                                                                    </FormattedMessage>
                                                                                                </Form.Field>
                                                                                                <Form.Field >
                                                                                                <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.phone"
                                                                                                                            defaultMessage='Phone'/></label>
                                                                                                <PhoneInput
                                                                                                        defaultCountry={intl.locale =='fr'?'FR':'US'}
                                                                                                        international
                                                                                                        //placeholder='' 
                                                                                                        value={client_phone}
                                                                                                        onChange={this.OnPhoneChange}
                                                                                                    />
                                                                                                {submitted && !this.handlePhoneValidation(client_phone) &&
                                                                                                    <label style={{color:'red',paddingLeft:'2em'}}><i>
                                                                                                        <FormattedMessage id="estimate.modal.form.input.phone.error.message"
                                                                                                                            defaultMessage='Invalid phone number'/> 
                                                                                                        </i></label>
                                                                                                    }
                                                                                                </Form.Field>
                                                                                                </Form.Group>
                                                                                            <Form.Group widths='equal'>
                                                                                                <Form.Field >
                                                                                                    <label style={styles.LabelGrey}><FormattedMessage id="estimate.modal.form.input.note"
                                                                                                                            defaultMessage='Additional infos'/></label>
                                                                                                <FormattedMessage id="estimate.modal.form.input.note.placeholder"
                                                                                                                    defaultMessage='website, position,comment ...'>
                                                                                                        {placeholder =>          
                                                                                                                <Form.TextArea type="text" 
                                                                                                                    placeholder={placeholder}
                                                                                                                    id="note" 
                                                                                                                    icon='text'
                                                                                                                    iconPosition='left'
                                                                                                                    value={client_note}
                                                                                                                    rows='1'
                                                                                                                    maxlength="300"
                                                                                                                    onChange={this.OnClientNoteChange}/>}
                                                                                                    </FormattedMessage>
                                                                                                </Form.Field>
                                                                                                </Form.Group>
                                                                                                <br/>
                                                                                        </Form>
                                                                                </Modal.Content>
                                                                                <Modal.Actions>
                                                                                    <br/>
                                                                                    <Button basic color='grey' onClick={this.hideModal}>
                                                                                        <FormattedMessage id="estimate.modal.update.button.cancel"
                                                                                                        defaultMessage='Cancel'/>
                                                                                    </Button>
                                                                                    <Button color='blue' 
                                                                                        labelPosition='right' 
                                                                                        content={<FormattedMessage id="estimate.modal.create.button.create"
                                                                                                                    defaultMessage='Create'/>}
                                                                                        icon='checkmark' 
                                                                                        onClick={(event) =>this.addClient(event)}
                                                                                        />
                                                                                    <br/>
                                                                                </Modal.Actions>   
                                                                            </Modal>  
                                                </Form.Field>
                                                </Form.Group>  
                                                </Form>
                                            </Table.Cell>
                                        <Table.Cell width={3}></Table.Cell>
                                        <Table.Cell width={5}  textAlign='center' style={{ paddingTop:'2em',paddingRight:'3em'  }} >
                                                <Segment style={{'background-color':'#ebf2f5',minHeight: 80, textAlign:'right' }} >
                                                     <Dimmer.Dimmable>
                                                     {loading &&
                                                        <Dimmer //active={adding||updating_menu||deleting} 
                                                                inline active
                                                                style={{'background-color':'#ebf2f5'}} 
                                                                inverted>
                                                            <Loader content='Loading'/>
                                                        </Dimmer>}
                                                        {estimate && 
                                                            <Header as='h4' color='blue'>
                                                                <FormattedMessage id="estimate.page.margin.title"
                                                                        defaultMessage='Gross Margin'/>&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                            <FormattedNumber value={Number(estimate.selling_value) - Number(estimate.selling_cost)} 
                                                                           style="currency" currencyDisplay="symbol" currency={currency}/>
                                                            <p style={{'color':'#b5b1b1','font-size':'15px'}}>
                                                                <i>
                                                                <FormattedNumber value={estimate.gross_margin} style="percent" maximumFractionDigits="2"/>
                                                                </i>
                                                            </p>
                                                            <span style={{fontSize:'15px', color:'#00b3b3'}}>
                                                                <FormattedMessage id="estimate.page.margin.foodcost"
                                                                                defaultMessage='Food Cost'/>&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                                 <FormattedNumber value={Number(estimate.selling_cost)} 
                                                                                 style="currency" currencyDisplay="symbol" currency={currency}/>
                                                            </span>
                                                            <p style={{'color':'#b5b1b1','font-size':'15px'}}>
                                                                <i>
                                                                <FormattedNumber value={estimate.food_cost} style="percent" maximumFractionDigits="2"/>
                                                                </i>
                                                            </p>
                                                            <span style={{fontSize:'15px', color:'black'}}>
                                                                <FormattedMessage id="estimate.page.margin.price"
                                                                                defaultMessage='Sales'/>&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                                <FormattedNumber value={estimate.selling_value} style="currency" 
                                                                     maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                                            </span>
                                                            <br/>
                                                            <span style={{fontSize:'14px', color:'grey'}}>
                                                                <FormattedMessage id="estimate.page.margin.vat"
                                                                                defaultMessage='vat'/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                                <FormattedNumber value={estimate.selling_vat} style="currency" 
                                                                     maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                                            </span>
                                                            <br/>
                                                            <span style={{fontSize:'16px', color:'black'}}>
                                                                <FormattedMessage id="estimate.page.margin.price.vat"
                                                                                defaultMessage='Sales vat incl.'/>&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                                <FormattedNumber value={Number(estimate.selling_value) + Number(estimate.selling_vat)} style="currency" 
                                                                     maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                                            </span>
                                                            
                                                        </Header>}
                                                    </Dimmer.Dimmable>
                                                </Segment>
                                            </Table.Cell>
                                           <Table.Cell width={8}></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Row> 
                            <Grid.Row columns={1} style={{ paddingLeft:'2em',paddingTop:'0em' }}>
                               <Grid.Column width={6}> 
                                 <Form>
                                   <label style={{paddingTop:'0em', color:'black'}}>
                                       <FormattedMessage id="estimate.list.note.title"
                                                         defaultMessage='Additional note' />
                                   </label>
                                   <FormattedMessage id="estimate.list.note.placeholder"
                                                  defaultMessage='quotation for X serving(s)'>
                                     {placeholder =>   
                                            <TextArea type="text" 
                                                      id="note" 
                                                      icon='text'
                                                      iconPosition='left'
                                                      value={note}
                                                      rows='2'
                                                      maxlength="600"
                                                      onChange={(e)=>this.onNoteChange(e)} 
                                                      placeholder={placeholder}
                                            />}
                                     </FormattedMessage>
                                  </Form>
                               </Grid.Column>
                            </Grid.Row>
{/*                              <Grid.Row columns={1}/> */}
                             <Grid.Row columns={1}/>
                            <Grid.Row columns={3}>
                                <Grid.Column  mobile={4} tablet={4} computer={2}/>
                                <Grid.Column mobile={5} tablet={0} computer={4}/>
                                <Grid.Column mobile={8} tablet={16} computer={8}> 
                                  <div className={className}>
                                  <Button compact color='teal' 
                                          floated='left'
                                          //loading={loading}
                                          style={styles.ButtonGreenNoMarginInvoice}
                                          onClick={(e)=>this.createDirectOrder(e)}
                                          >{/* <Icon name='calendar' /> */}
                                          <FormattedMessage id="estimate.floatingmenu.button.produce"
                                                            defaultMessage='Produce' />
                                   </Button>
                                   <Button compact color='blue' 
                                          floated='left'
                                          //loading={loading}
                                          style={styles.ButtonGreenNoMarginInvoice}
                                          onClick={(e)=>this.createBillEstimate(e)}
                                          >{/* <Icon name='laptop' /> */}
                                          <FormattedMessage id="estimate.floatingmenu.button.bill"
                                                            defaultMessage='Bill' />
                                   </Button>
                                  <Button compact basic color='blue' 
                                          floated='left'
                                          //loading={loading}
                                          style={styles.ButtonOrangeNoMarginInvoice}
                                          onClick={(e)=>this.generateDocBackend(e,false)}
                                          ><Icon name='print' />
                                          <FormattedMessage id="estimate.floatingmenu.button.print"
                                                            defaultMessage='Print' />
                                   </Button>
                                   <Button compact basic color='blue' 
                                          floated='left'
                                          //loading={loading}
                                          style={styles.ButtonOrangeNoMarginInvoice}
                                          onClick={(e)=>this.generateDocBackend(e,true)}
                                          > <Icon name='send' />
                                          <FormattedMessage id="estimate.floatingmenu.button.send"
                                                            defaultMessage='Send' />
                                   </Button>
                                 {/*&nbsp;  &nbsp; &nbsp;&nbsp; */}
                                  <Popup trigger=
                                            { <Button //circular 
                                                      //color='blue'
                                                      style={styles.ButtonBlueAddNoMarginOrder}
                                                      size='small' 
                                                      compact
                                                      //icon='book' 
                                                      onClick={(e)=>this.toggleItemsMenu(e,'recipe')}>
                                                          <Icon name='add circle'/>{' '}
                                                          <FormattedMessage id="estimate.floatingmenu.recipes.icon.title"
                                                                            defaultMessage='Recipes'/>
                                               </Button>}
                                             content={<FormattedMessage id="estimate.floatingmenu.recipes.title"
                                                                        defaultMessage='Add recipes'/>}
                                            position='top center'
                                            inverted
                                    />
                                  </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                                    {(loading || adding )&&
                                          <Dimmer active inverted>
                                                <Loader inverted content='Loading' />
                                          </Dimmer> }   
                                    <Table //selectable 
                                           unstackable 
                                           compact 
                                           singleLine 
                                           //color={'grey'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={4}>
                                                    <FormattedMessage id="estimate.list.selected.column.element.recipe"
                                                                       defaultMessage='Item(s)' />
                                                    {/*  <p style={{'color':'#b5b1b1','font-size':'11px',marginLeft:'1em'}}>
                                                        <i><FormattedMessage id="estimate.list.selected.column.element.category"
                                                                          defaultMessage='Category'/></i></p> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    {/*  <FormattedMessage id="order.list.selected.column.units"
                                                                          defaultMessage='Unit(s)' /> */}
                                                        <FormattedMessage id="estimate.list.selected.column.units"
                                                                          defaultMessage='Amount' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                        <FormattedMessage id="estimate.list.selected.column.yield"
                                                                          defaultMessage='Yield' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                       <FormattedMessage id="estimate.list.selected.column.unitcost"
                                                                          defaultMessage='Food unit cost' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                       <FormattedMessage id="estimate.list.selected.column.sellingunit"
                                                                          defaultMessage='Selling unit' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                       <FormattedMessage id="estimate.list.selected.column.selling"
                                                                          defaultMessage='Selling' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                       <FormattedMessage id="estimate.list.selected.column.tax"
                                                                          defaultMessage='Sales tax' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {!emptyOrders &&  orderList && orderList.length > 0 &&
                                               this.addOrdersRows(orderList)
                                        }                                     
                                        {emptyOrders && 
                                         <Table.Row>
                                            <Table.Cell>
                                                <FormattedMessage id="estimate.list.selected.column.empty.message"
                                                                       defaultMessage='No item added yet' />
                                            </Table.Cell>
                                          </Table.Row>
                                        }
                                        </Table.Body>
{/*                                         {!emptyOrders && 
                                          <Table.Footer fullWidth>
                                            <Table.Row>
                                                    <Table.HeaderCell colSpan='8'>
                                                        <Button compact color='teal' 
                                                                floated='right'
                                                                size='small'
                                                                loading={loading_o}
                                                                style={styles.ButtonGreenNoMargin}
                                                                //disabled={!this.handleValidation() 
                                                                 //   || !this.assertNumericFields() || !this.handleIngredientsValidation()}
                                                                onClick={(e)=>this.updateEstimate(e,false)}>
                                                                <FormattedMessage id="estimate.list.selected.button.update"
                                                                                    defaultMessage='Update' />
                                                            </Button>
                                                    </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>} */}
                                        <Modal open={this.state.activeModalPremium}
                                                    dimmer='true' 
                                                    style={styles.Modal} 
                                                    onClose={this.hideModal}
                                                    closeIcon>
                                                <Modal.Header>
                                                    <FormattedMessage id="estimate.estimate.header.title"
                                                                    defaultMessage='Create client estimate'/>
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                    <Image src={img_performance} size ='small' rounded/>
                                                    <br/>
                                                    <Message.Content style={{marginLeft:'1em'}}>
                                                        <Message.Header color='black'>
                                                            <FormattedMessage id="estimate.premium.message.upgradeplan"
                                                                                defaultMessage='PREMIUM Feature'/>
                                                        </Message.Header>
                                                        <br/>
                                                        <FormattedMessage id="estimate.premium.title.message.upgradeplan"
                                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                                        <br/><br/>
                                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                            <FormattedMessage id="estimate.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                        </Button>                                      
                                                    </Message.Content>
                                                </Message>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                                    {/* <Icon name='exit' /> */}
                                                    <FormattedMessage id="estimate.premium.button.cancel"
                                                                    defaultMessage='Cancel'/>
                                                    </Button>
                                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                                    <FormattedMessage id="estimate.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                    </Button>
                                                </Modal.Actions>
                                        </Modal>
                                    </Table>
                                    <br/> <br/>
                                </Grid.Column>         
                            </Grid.Row>
                        </Grid>
                    </SideLeftUncoverProductions>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { recipes,categoriesRecipe,client,alert,dispatch } = state;
    const { loading_r,loading,adding,estimate} = state.recipes;
    const { loading_cr } = state.categoriesRecipe;
    const { subscription,customer } = state.payment;

    return {
        recipes,
        categoriesRecipe,
        alert,
        dispatch,
        loading,
        adding,
        client,
        loading_r,
        loading_cr,
        estimate,
        subscription,
        customer
    };
}

const connectedEstimateDetails = injectIntl(connect(mapStateToProps)(EstimateDetails));
export { connectedEstimateDetails as EstimateDetails };
 
