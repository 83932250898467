import { recipeConstants } from '../_constants';
import { recipeService } from '../_api';
import { alertActions } from './';
import { history,isEmpty } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const recipesActions = {
    getAll,
    add,
    get,
    update,
    updateInstructions,
    delete: _delete,
    deleteSelect,
    categorySelect,
    count,
    stats,
    deleteingredient,
    deletesubrecipe,
    export_csv,
    export_xlsx,
    renderPdf,
    renderOrder,
    renderOrderCsv,
    saveOrder,
    deduceStockOrder,
    addStockOrder,
    destockOrder,
    destockOrderRecipes,
    stockOrderRecipes,
    createSuppliersOrders,
    getOrder,
    getOrders,
    deleteOrder,
    countOrders,
    updateOrder,
    sendOrder,
    deleteSelectOrder,
    updateOrderQuantity,
    roundOrderQuantity,
    addOrderIngredient,
    deleteIngredientOrder,
    updateOrderRecipeQuantity,
    updateOrderRecipeTraceability,
    deleteRecipeOrder,
    addOrderRecipe,
    updateStateSelectOrder,
    updateImage,
    updateImages,
    getImage,
    deleteImage,
    deleteImages,
    recipe_weight_variation,
    labelTag,
    updateLabelTag,
    getAllMenu,
    getMenu,
    addMenu,
    updateMenu,
    deleteMenu,
    addRecipeMenu,
    updateRecipeMenu,
    deleteRecipeMenu,
    addIngredientMenu,
    updateIngredientMenu,
    deleteIngredientMenu,
    downloadMenu,
    countMenus,
    allergensMatrix,
    getAllergensRecipes,
    getOrdersRecipes,
    update_stock,
    reset_stock,
    inventory,
    updateDaysValidity,
    getEstimate,
    getEstimates,
    saveEstimate,
    updateEstimate,
    updateEstimateInfos,
    deleteEstimate,
    updateEstimateRecipeQuantity,
    deleteRecipeEstimate,
    addEstimateRecipe,
    renderEstimate,
    deleteSelectEstimate,
    countEstimates,
    billEstimate,
    sendEstimate
    //sendPOS
};

function getAll(short=false) {
    return dispatch => {
        dispatch(request());

        recipeService.getAll(short)
            .then(
                recipes => dispatch(success(recipes)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GETALL_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GETALL_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GETALL_FAILURE, error } }
}


function get(id,link) {
    return dispatch => {
        dispatch(request());

        recipeService.get(id)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    if (link){
                        history.push(link);
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_FAILURE, error } }
}

function add(recipe,link, isSameLink=0) {
    return dispatch => {
        dispatch(request());

        recipeService.add(recipe)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    //dispatch(alertActions.success({title:'Recipe Action',content:'Recipe Added Successfully'}));
                    let title_text = <FormattedMessage id="recipe.actions.recipe.add.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.add.success.content"
                                                         defaultMessage="Recipe Added Successfully"/>
                                
                    dispatch(alertActions.success({title: "recipe.actions.recipe.add.success.title",
                                                   content:"recipe.actions.recipe.add.success.content"}));

                     if (link){
                        if (isSameLink) history.push('/');
                        history.push(link);
                    }else{
                        //history.push('/');
                        history.push('/recipes');
                    }
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    let title_text = <FormattedMessage id="recipe.actions.recipe.add.error.title"
                                                       defaultMessage="Recipe Action Error"/>

                    if ("yield_count" in error) {
                        //dispatch(alertActions.error("yield_count: " + error.yield_count[0]))
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.yield_count"
                                                             defaultMessage="yield_count: {message}"
                                                             values={{message:error.yield_count[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                     content:"recipe.actions.recipe.add.error.yield_count"}));


                     }else if ("name" in error) {
                        //dispatch(alertActions.error("name: " +error.name[0]));
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.name"
                                                            defaultMessage="name: {message}"
                                                            values={{message:error.name[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.name"}));
                     }else if ("yield_units" in error) {
                        //dispatch(alertActions.error("yield_units: " + error.yield_units[0]));
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.yieldunits"
                                                            defaultMessage="yield_units: {message}"
                                                            values={{message:error.yield_units[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.yieldunits"}));
                    }else if ("ingredients" in error) {
                        //dispatch(alertActions.error("ingredients: " + error.ingredients[0]));
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.ingredients"
                                                            defaultMessage="ingredients: {message}"
                                                            values={{message:error.ingredients[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.ingredients"}));
                    }else if ("subrecipes" in error) {
                        //dispatch(alertActions.error("subrecipes: " + error.recipes[0]));
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.subrecipes"
                                                            defaultMessage="subrecipes: {message}"
                                                            values={{message:error.recipes[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.subrecipes"}));
                    }else if ("category" in error) {
                        //dispatch(alertActions.error("category: " + error.category[0]));
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.category"
                                                            defaultMessage="category: {message}"
                                                            values={{message:error.category[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.category"}));
                    }else if ("non_field_errors" in error) {
                        //dispatch(alertActions.error(error.non_field_errors[0]));
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.non_field_errors"
                                                            defaultMessage="{message}"
                                                            values={{message:error.non_field_errors[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.non_field_errors"}));
                    }else{
                        //dispatch(alertActions.error(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));
                    }
                }

            );
    };

    function request() { return { type: recipeConstants.ADD_REQUEST } }
    function success(recipes) { return { type: recipeConstants.ADD_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.ADD_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request());

        recipeService.delete(id)
            .then(
                recipes => { 
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.recipe.delete.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.delete.success.content"
                                                          defaultMessage="Recipe(s) Deleted Successfully"/>
                    dispatch(alertActions.success({title:'recipe.actions.recipe.delete.success.title',
                                                   content:'recipe.actions.recipe.delete.success.content'}));
                    //dispatch(alertActions.clear());
                    history.push('/');
                    history.push('/recipes');
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');
                    history.push('/recipes');
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_FAILURE, error } }
}


function deleteSelect(recipes) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteSelect(recipes)
            .then(
                recipes => { 
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.recipe.delete.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.delete.success.content"
                                                          defaultMessage="Recipe(s) Deleted Successfully"/>
                    dispatch(alertActions.success({title:'recipe.actions.recipe.delete.success.title',
                                                   content:'recipe.actions.recipe.delete.success.content'}));
                    //dispatch(alertActions.clear());
                    history.push('/');
                    history.push('/recipes');
                },
                error => {

                    if (error.status = 401)
                    {
                        //Case HTTP_401_UNAUTHORIZED, only warn some not deleted 
                        dispatch(warn(error))
                        let title_text = <FormattedMessage id="recipe.actions.recipe.delete.warn.title"
                                                defaultMessage="Recipe Action Warning"/>
                        let content_text = <FormattedMessage id="recipe.actions.recipe.delete.warn.content"
                                                defaultMessage="Some recipes not deleted (cause used as sub-recipes)"/>
                        dispatch(alertActions.warn({title:'recipe.actions.recipe.delete.warn.title',
                                                    content:'recipe.actions.recipe.delete.warn.content'}));
                    }else{

                        dispatch(failure(error));
                        //dispatch(alertActions.error(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));
                    }

                    history.push('/');
                    history.push('/recipes');
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_SUCCESS, recipes } }
    function warn(recipes) { return { type: recipeConstants.DELETE_WARN, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_FAILURE, error } }
}



function count() {
    return dispatch => {
        dispatch(request());

        recipeService.count()
            .then(
                recipes => dispatch(success(recipes)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.COUNT_REQUEST } }
    function success(recipes) { return { type: recipeConstants.COUNT_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.COUNT_FAILURE, error } }
}

function stats() {
    return dispatch => {
        dispatch(request());

        recipeService.stats()
            .then(
                recipes => dispatch(success(recipes)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.STATS_REQUEST } }
    function success(recipes) { return { type: recipeConstants.STATS_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.STATS_FAILURE, error } }
}

function update(id,recipe,link,isSameLink=0) {
    return dispatch => {
        dispatch(request());

        recipeService.update(id,recipe)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.recipe.update.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.update.success.content"
                                                         defaultMessage="Recipe Updated Successfully"/>


                    if (link){

                        dispatch(alertActions.success({title:'recipe.actions.recipe.update.success.title',
                                                       content:'recipe.actions.recipe.update.success.content'}));
                        if (isSameLink) history.push('/');
                        history.push(link);
                    } else {
                        //history.push('/');
                        //history.push('/recipedetails/'+id);
                    }

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');
                    history.push('/recipedetails/'+id);
                }

            );
    };

    function request() { return { type: recipeConstants.UPDATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_FAILURE, error } }
}

function updateInstructions(id,instructions,silent = false) {
    return dispatch => {
        dispatch(request());
        recipeService.updateInstructions(id,instructions)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    if (silent === false){

                        let title_text = <FormattedMessage id="recipe.actions.recipe.update.instructions.success.title"
                                                        defaultMessage="Recipe Action"/>
                        let content_text = <FormattedMessage id="recipe.actions.recipe.update.instructions.success.content"
                                                            defaultMessage="Prepare Instructions Updated Successfully"/>
                        dispatch(alertActions.success({title:'recipe.actions.recipe.update.instructions.success.title',
                                                    content:'recipe.actions.recipe.update.instructions.success.content'}));
                        //history.push('/recipes');
                    }
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/recipes');   
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_INSTRUCTIONS_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_INSTRUCTIONS_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_INSTRUCTIONS_FAILURE, error } }
}

function updateImage(id,image) {
    return dispatch => {
        dispatch(request());

        recipeService.updateImage(id,image)
            .then(
                recipes =>{
                    dispatch(success(recipes));
/*                     let title_text = <FormattedMessage id="recipe.actions.recipe.update.image.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.update.image.success.content"
                                                         defaultMessage="Image Updated Successfully"/>
                    dispatch(alertActions.success({title:'recipe.actions.recipe.update.image.success.title',
                                                   content:'recipe.actions.recipe.update.image.success.content'})); */
                     //history.push('/recipes/');
                     //history.push('/recipedetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    //history.push('/recipedetails/'+id);   
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_IMAGE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_IMAGE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_IMAGE_FAILURE, error } }
}

function updateImages(id,image,editor,range) {
    return dispatch => {
        dispatch(request());
        recipeService.updateImages(id,image)
            .then(
                recipes =>{
                    //dispatch(success(recipes));
                    if (range.index !== null && range.index !== undefined) {
                        editor.getEditor().insertEmbed(range.index,
                                                       'image',
                                                       recipes.url );                                                      
                        editor.getEditor().setSelection(range.index + 1);
                        //console.log(editor.getEditor().root.innerHTML)
                        console.log('success')
                        setTimeout(() => {
                            recipeService.updateInstructions(id,editor.getEditor().root.innerHTML,true)
                            .then(
                                recipes =>{
                                    //dispatch(success(recipes));
                                    //dispatch(alertActions.success({title:'recipe.actions.recipe.update.instructions.success.title',
                                     //                              content:'recipe.actions.recipe.update.instructions.success.content'}));
                                    //history.push('/recipes');
                                },
                                error => {
                                    dispatch(failure(error));
                                    //dispatch(alertActions.error(error));
                                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                                content:error.message,
                                                                stringMode: true }));
                                }
                            );
                        },100);
                    }
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    //history.push('/recipedetails/'+id);   
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_IMAGE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_IMAGE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_IMAGE_FAILURE, error } }
}



function getImage(id) {
    return dispatch => {
        dispatch(request());

        recipeService.getImage(id)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true })); 
                }
            );
    };

    function request() { return { type: recipeConstants.GET_IMAGE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_IMAGE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_IMAGE_FAILURE, error } }
}


function deleteImage(id) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteImage(id)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    //let title_text = <FormattedMessage id="recipe.actions.recipe.delete.image.success.title"
                    //                                   defaultMessage="Recipe Action"/>
                    //let content_text = <FormattedMessage id="recipe.actions.recipe.delete.image.success.content"
                    //                                     defaultMessage="Image Deleted Successfully"/>
                    ///dispatch(alertActions.success({title:'recipe.actions.recipe.delete.image.success.title',
                    //                               content:'recipe.actions.recipe.delete.image.success.content'}));
                    //history.push('/');
                    //history.push('/recipedetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    //dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                    //                            content:error.message,
                    //                            stringMode: true }));
                   //history.push('/recipedetails/'+id);   
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_IMAGE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_IMAGE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_IMAGE_FAILURE, error } }
}


function deleteImages(id,path) {
    return dispatch => {
        dispatch(request());
        recipeService.deleteImages(id,path)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    //console.log('delete success: ' + path)
                    //let title_text = <FormattedMessage id="recipe.actions.recipe.delete.image.success.title"
                    //                                   defaultMessage="Recipe Action"/>
                    //let content_text = <FormattedMessage id="recipe.actions.recipe.delete.image.success.content"
                    //                                     defaultMessage="Image Deleted Successfully"/>
                    ///dispatch(alertActions.success({title:'recipe.actions.recipe.delete.image.success.title',
                    //                               content:'recipe.actions.recipe.delete.image.success.content'}));
                    //history.push('/');
                    //history.push('/recipedetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    //dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                    //                            content:error.message,
                    //                            stringMode: true }));
                   //history.push('/recipedetails/'+id);   
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_IMAGE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_IMAGE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_IMAGE_FAILURE, error } }
}



function deleteingredient(id,id_ingredient) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteingredient(id,id_ingredient)
            .then(
                recipes => { 
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.recipe.delete.ingredient.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.delete.ingredient.success.content"
                                                         defaultMessage="Ingredient Deleted Successfully"/>
                    dispatch(alertActions.success({title:'recipe.actions.recipe.delete.ingredient.success.title',
                                                   content:'recipe.actions.recipe.delete.ingredient.success.content'}));
                    history.push('/');
                    history.push('/recipedetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');
                    history.push('/recipedetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_INGREDIENT_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_INGREDIENT_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_INGREDIENT_FAILURE, error } }
}


function deletesubrecipe(id,id_id_recipe) {
    return dispatch => {
        dispatch(request());

        recipeService.deletesubrecipe(id,id_id_recipe)
            .then(
                recipes => { 
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.recipe.delete.subrecipe.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.delete.subrecipe.success.content"
                                                         defaultMessage="Subrecipe Deleted Successfully"/>
                    dispatch(alertActions.success({title:'recipe.actions.recipe.delete.subrecipe.success.title',
                                                   content:'recipe.actions.recipe.delete.subrecipe.success.content'}));
                    history.push('/');
                    history.push('/recipedetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');
                    history.push('/recipedetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_SUBRECIPE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_SUBRECIPE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_SUBRECIPE_FAILURE, error } }
}



function export_csv(parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.export_csv(parameters)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.EXPORT_CSV_REQUEST } }
    function success(recipes) { return { type: recipeConstants.EXPORT_CSV_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.EXPORT_CSV_FAILURE, error } }
}


function export_xlsx(parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.export_xlsx(parameters)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.EXPORT_XLSX_REQUEST } }
    function success(recipes) { return { type: recipeConstants.EXPORT_XLSX_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.EXPORT_XLSX_FAILURE, error } }
}


function renderPdf(properties) {
    return dispatch => {
        dispatch(request());

        recipeService.renderPdf(properties)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_PDF_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_PDF_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_PDF_FAILURE, error } }
}


function renderOrder(properties) {
    return dispatch => {
        dispatch(request());

        recipeService.renderOrder(properties)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_ORDER_PDF_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_ORDER_PDF_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_ORDER_PDF_FAILURE, error } }
}


function renderOrderCsv(id,name, include_costs=true) {
    return dispatch => {
        dispatch(request());

        recipeService.renderOrderCsv(id,name,include_costs)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_ORDER_CSV_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_ORDER_CSV_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_ORDER_CSV_FAILURE, error } }
}


function saveOrder(properties,silent=false, prod=false) {
    return dispatch => {
        dispatch(request());

        recipeService.saveOrder(properties)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    
                    if (silent === false){
                        if (prod == true){
                            let title_text = <FormattedMessage id="productions.actions.production.save.success.title"
                                                        defaultMessage="Production Action"/>
                            let content_text = <FormattedMessage id="productions.actions.production.save.success.content"
                                                        defaultMessage="Production Saved Updated Successfully"/>
                            dispatch(alertActions.success({title:'productions.actions.production.save.success.title',
                                                    content:'productions.actions.production.save.success.content'}));
                            history.push('/productions');
                        }else{
                            let title_text = <FormattedMessage id="orders.actions.order.save.success.title"
                                                        defaultMessage="Order Action"/>
                            let content_text = <FormattedMessage id="orders.actions.order.save.success.content"
                                                        defaultMessage="Order Saved Updated Successfully"/>
                            dispatch(alertActions.success({title:'orders.actions.order.save.success.title',
                                                          content:'orders.actions.order.save.success.content'}));
                                                          history.push('/orderslist');
                            //history.push('/orderdetails/'+recipes.id+'/');
                        }
                    }
                },
                error => {
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    if (prod == true){
                        history.push('/productions');
                    }
                    else{
                        history.push('/');
                        history.push('/orderslist');
                    }

                }
            );
    };

    function request() { return { type: recipeConstants.SAVE_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.SAVE_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.SAVE_ORDER_FAILURE, error } }
}

function getOrder(id) {
    return dispatch => {
        dispatch(request());

        recipeService.getOrder(id)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_ORDER_FAILURE, error } }
}


function deleteOrder(id, prod=false) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteOrder(id)
            .then(
                recipes => {
                    dispatch(success(recipes));

                    if (prod == true){
                        let title_text = <FormattedMessage id="productions.actions.production.delete.success.title"
                                                    defaultMessage="Production Action"/>
                        let content_text = <FormattedMessage id="productions.actions.production.delete.success.content"
                                                    defaultMessage="Production(s) Deleted Successfully"/>
                        dispatch(alertActions.success({title:'productions.actions.production.delete.success.title',
                                                       content:'productions.actions.production.delete.success.content'}));
                        history.push('/');
                        history.push('/productions');
                    }else{
                        let title_text = <FormattedMessage id="orders.actions.order.delete.success.title"
                                                    defaultMessage="Order Action"/>
                        let content_text = <FormattedMessage id="orders.actions.order.delete.success.content"
                                                    defaultMessage="Order(s) Deleted Successfully"/>
                        dispatch(alertActions.success({title:'orders.actions.order.delete.success.title',
                                                content:'orders.actions.order.delete.success.content'}));
                        history.push('/');
                        history.push('/orderslist');
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderslist');
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_ORDER_FAILURE, error } }
}


function getOrders(short=false,prod=false) {
    return dispatch => {
        dispatch(request());

        recipeService.getOrders(short,prod)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_ORDERS_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_ORDERS_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_ORDERS_FAILURE, error } }
}


function countOrders() {
    return dispatch => {
        dispatch(request());

        recipeService.countOrders()
            .then(
                recipes => dispatch(success(recipes)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.COUNT_ORDERS_REQUEST } }
    function success(recipes) { return { type: recipeConstants.COUNT_ORDERS_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.COUNT_ORDERS_FAILURE, error } }
}

function updateOrder(id,parameters, silent=false,prod=false) {
    return dispatch => {
        dispatch(request());

        recipeService.updateOrder(id,parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                
                    if (silent == false){
                        if (prod == true){

                            let title_text = <FormattedMessage id="productions.actions.production.update.success.title"
                                                        defaultMessage="Production Action"/>
                            let content_text = <FormattedMessage id="productions.actions.production.update.success.content"
                                                                defaultMessage="Production(s) updated successfully"/>
                            dispatch(alertActions.success({title:'productions.actions.production.update.success.title',
                                                          content:'productions.actions.production.update.success.content'}));

                            history.push('/');
                            history.push('/productiondetails/'+id+'/');
                        }else{
                            let title_text = <FormattedMessage id="orders.actions.order.update.success.title"
                                                        defaultMessage="Order Action"/>
                            let content_text = <FormattedMessage id="orders.actions.order.update.success.content"
                                                                defaultMessage="Order(s) updated successfully"/>
                            dispatch(alertActions.success({title:'orders.actions.order.update.success.title',
                                                    content:'orders.actions.order.update.success.content'}));

                            history.push('/');
                            //history.push('/orderslist');
                            history.push('/orderdetails/'+id+'/');
                        }
                    }
                },
                error => {
                        dispatch(failure(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                     content:error.message,
                                                     stringMode: true }));
                         if (prod == true){
                            history.push('/');
                            history.push('/productiondetails/'+id);
                        }
                        else{
                            history.push('/');
                            history.push('/orderdetails/'+id);
                        }

                    }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_ORDER_FAILURE, error } }
}



function sendOrder(id,parameters, silent=false) {
    return dispatch => {
        dispatch(request());

        recipeService.sendOrder(parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                   /* let title_text = <FormattedMessage id="orders.actions.order.send.success.title"
                                                    defaultMessage="Order Action"/>
                    let content_text = <FormattedMessage id="orders.actions.order.send.success.content"
                                                            defaultMessage="Order sent successfully"/>
                    dispatch(alertActions.success({title:'orders.actions.order.send.success.title',
                                                  content:'orders.actions.order.send.success.content'}));
                
                     if (silent == false){
                        history.push('/');
                        //history.push('/orderslist');
                        history.push('/orderdetails/'+id+'/');
                    } */
                },
                error => {
                        dispatch(failure(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                     content:error.message,
                                                     stringMode: true }));
                        history.push('/');
                        history.push('/orderdetails/'+id);
                    }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_ORDER_FAILURE, error } }
}


function deleteSelectOrder(orders,prod=false) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteSelectOrder(orders)
            .then(
                recipes => { 
                    dispatch(success(recipes));

                    //dispatch(alertActions.clear());
                    if (prod == true){
                        let title_text = <FormattedMessage id="productions.actions.production.delete.success.title"
                                                    defaultMessage="Production Action"/>
                        let content_text = <FormattedMessage id="productions.actions.production.delete.success.content"
                                                    defaultMessage="Production(s) Deleted Successfully"/>
                        dispatch(alertActions.success({title:'productions.actions.production.delete.success.title',
                                                       content:'productions.actions.production.delete.success.content'}));
                        history.push('/');
                        history.push('/productions');
                    }else{
                        let title_text = <FormattedMessage id="orders.actions.order.delete.success.title"
                                                    defaultMessage="Order Action"/>
                        let content_text = <FormattedMessage id="orders.actions.order.delete.success.content"
                                                    defaultMessage="Order(s) Deleted Successfully"/>
                        dispatch(alertActions.success({title:'orders.actions.order.delete.success.title',
                                                content:'orders.actions.order.delete.success.content'}));
                        history.push('/');
                        history.push('/orderslist');
                    }
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));

                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_ORDER_FAILURE, error } }
}


function updateOrderQuantity(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.updateOrderQuantity(id,parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                    //history.push('/');
                    //history.push('/orderdetails/'+id+'/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderdetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_FAILURE, error } }
}


function roundOrderQuantity(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.roundOrderQuantity(id,parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                    history.push('/');
                    history.push('/orderdetails/'+id+'/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderdetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_FAILURE, error } }
}


function deleteIngredientOrder(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteIngredientOrder(id,parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                    history.push('/');
                    history.push('/orderdetails/'+id+'/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderdetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_INGREDIENT_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_INGREDIENT_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_INGREDIENT_ORDER_FAILURE, error } }
}


function addOrderIngredient(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.addOrderIngredient(id,parameters)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    //history.push('/');
                    //history.push('/orderdetails/'+id+'/');
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    //history.push('/');
                    //history.push('/orderdetails/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.ADD_INGREDIENT_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.ADD_INGREDIENT_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.ADD_INGREDIENT_ORDER_FAILURE, error } }
}


function updateOrderRecipeQuantity(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.updateOrderRecipeQuantity(id,parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                    history.push('/');
                    history.push('/productiondetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/productiondetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_FAILURE, error } }
}


function updateOrderRecipeTraceability(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.updateOrderRecipeTraceability(id,parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                    history.push('/');
                    history.push('/productiondetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/productiondetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_QUANTITY_ORDER_FAILURE, error } }
}


function deleteRecipeOrder(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteRecipeOrder(id,parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                    history.push('/');
                    history.push('/productiondetails/'+id+'/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/productiondetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_INGREDIENT_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_INGREDIENT_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_INGREDIENT_ORDER_FAILURE, error } }
}

function addOrderRecipe(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.addOrderRecipe(id,parameters)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    history.push('/');
                    history.push('/productiondetails/'+id+'/');
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/productiondetails/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.ADD_INGREDIENT_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.ADD_INGREDIENT_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.ADD_INGREDIENT_ORDER_FAILURE, error } }
}

function deduceStockOrder(id) {
    return dispatch => {
        dispatch(request());

        recipeService.deduceStockOrder(id)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="orders.actions.order.deduce.success.title"
                                                    defaultMessage="Order Action"/>
                    let content_text = <FormattedMessage id="orders.actions.order.deduce.success.content"
                                                            defaultMessage="Order updated successfully"/>
                     dispatch(alertActions.success({title:'orders.actions.order.deduce.success.title',
                                                   content:'orders.actions.order.deduce.success.content'}));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderdetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.DEDUCE_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DEDUCE_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DEDUCE_ORDER_FAILURE, error } }
}


function addStockOrder(id) {
    return dispatch => {
        dispatch(request());

        recipeService.addStockOrder(id)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="orders.actions.order.add.success.title"
                                                    defaultMessage="Order Action"/>
                    let content_text = <FormattedMessage id="orders.actions.order.add.success.content"
                                                            defaultMessage="Stock updated successfully"/>
                     dispatch(alertActions.success({title:'orders.actions.order.add.success.title',
                                                   content:'orders.actions.order.add.success.content'}));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderdetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.ADDSTOCK_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.ADDSTOCK_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.ADDSTOCK_ORDER_FAILURE, error } }
}


function destockOrder(id) {
    return dispatch => {
        dispatch(request());

        recipeService.destockOrder(id)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="orders.actions.order.destock.success.title"
                                                    defaultMessage="Order Action"/>
                    let content_text = <FormattedMessage id="orders.actions.order.destock.success.content"
                                                            defaultMessage="Stock updated successfully"/>
                     dispatch(alertActions.success({title:'orders.actions.order.destock.success.title',
                                                   content:'orders.actions.order.destock.success.content'}));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderdetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.DESTOCK_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DESTOCK_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DESTOCK_ORDER_FAILURE, error } }
}


function destockOrderRecipes(id) {
    return dispatch => {
        dispatch(request());

        recipeService.destockOrderRecipes(id)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="orders.actions.order.destock.success.title"
                                                    defaultMessage="Order Action"/>
                    let content_text = <FormattedMessage id="orders.actions.order.destock.success.content"
                                                            defaultMessage="Stock updated successfully"/>
                     dispatch(alertActions.success({title:'orders.actions.order.destock.success.title',
                                                   content:'orders.actions.order.destock.success.content'}));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderdetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.DESTOCK_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DESTOCK_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DESTOCK_ORDER_FAILURE, error } }
}


function stockOrderRecipes(id) {
    return dispatch => {
        dispatch(request());

        recipeService.stockOrderRecipes(id)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="orders.actions.order.destock.success.title"
                                                    defaultMessage="Order Action"/>
                    let content_text = <FormattedMessage id="orders.actions.order.destock.success.content"
                                                            defaultMessage="Stock updated successfully"/>
                     dispatch(alertActions.success({title:'orders.actions.order.destock.success.title',
                                                   content:'orders.actions.order.destock.success.content'}));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderdetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.STOCK_RECIPES_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.STOCK_RECIPES_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.STOCK_RECIPES_ORDER_FAILURE, error } }
}


function createSuppliersOrders(id) {
    return dispatch => {
        dispatch(request());

        recipeService.createSuppliersOrders(id)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="orders.actions.order.createSuppliersOrders.success.title"
                                                    defaultMessage="Order Action"/>
                    let content_text = <FormattedMessage id="orders.actions.order.createSuppliersOrders.success.content"
                                                            defaultMessage="Suppliers orders created successfully"/>
                     dispatch(alertActions.success({title:'orders.actions.order.createSuppliersOrders.success.title',
                                                   content:'orders.actions.order.createSuppliersOrders.success.content'}));

                     history.push('/orderslist/');                                                   
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/orderdetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.CREATE_SUPPLIERS_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.CREATE_SUPPLIERS_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.CREATE_SUPPLIERS_ORDER_FAILURE, error } }
}


function recipe_weight_variation(id,params) {
    return dispatch => {
        dispatch(request());

        recipeService.recipe_weight_variation(id,params)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.recipe.weight.variation.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.weight.variation.success.content"
                                                         defaultMessage="Updated weight variation"/>
                    dispatch(alertActions.success({title:'recipe.actions.recipe.weight.variation.success.title',
                                                   content:'recipe.actions.recipe.weight.variation.success.content'}));
                     //history.push('/');
                     //history.push('/recipedetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');                       
                    history.push('/recipedetails/'+id);   
                }
            );
    };

    function request() { return { type: recipeConstants.WEIGHT_VARIATION_REQUEST } }
    function success(recipes) { return { type: recipeConstants.WEIGHT_VARIATION_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.WEIGHT_VARIATION_FAILURE, error } }
}


function labelTag(id) {
    return dispatch => {
        dispatch(request());

        recipeService.labelTag(id)
            .then(
                recipes =>{
                    dispatch(success(recipes));
/*                     let title_text = <FormattedMessage id="recipe.actions.recipe.update.image.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.update.image.success.content"
                                                         defaultMessage="Image Updated Successfully"/>
                    dispatch(alertActions.success({title:'recipe.actions.recipe.update.image.success.title',
                                                   content:'recipe.actions.recipe.update.image.success.content'})); */
                     //history.push('/recipes/');
                     //history.push('/recipedetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    //history.push('/recipedetails/'+id);   
                }
            );
    };

    function request() { return { type: recipeConstants.LABEL_TAG_REQUEST } }
    function success(recipes) { return { type: recipeConstants.LABEL_TAG_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.LABEL_TAG_FAILURE, error } }
}


function updateLabelTag(id,params) {
    return dispatch => {
        dispatch(request());

        recipeService.updateLabelTag(id,params)
            .then(
                recipes =>{
                    dispatch(success(recipes));
/*                     let title_text = <FormattedMessage id="recipe.actions.recipe.update.image.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.update.image.success.content"
                                                         defaultMessage="Image Updated Successfully"/>
                    dispatch(alertActions.success({title:'recipe.actions.recipe.update.image.success.title',
                                                   content:'recipe.actions.recipe.update.image.success.content'})); */
                     //history.push('/recipes/');
                     //history.push('/recipedetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');                       
                    history.push('/recipedetails/'+id);    
                }
            );
    };

    function request() { return { type: recipeConstants.LABEL_TAG_REQUEST } }
    function success(recipes) { return { type: recipeConstants.LABEL_TAG_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.LABEL_TAG_FAILURE, error } }
}



function getAllMenu() {
    return dispatch => {
        dispatch(request());

        recipeService.getAllMenu()
            .then(
                recipes => dispatch(success(recipes)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GETALL_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GETALL_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GETALL_MENU_FAILURE, error } }
}

function getMenu(id) {
    return dispatch => {
        dispatch(request());

        recipeService.getMenu(id)
            .then(
                recipes => dispatch(success(recipes)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_MENU_FAILURE, error } }
}


function addMenu(menu,link) {
    return dispatch => {
        dispatch(request());

        recipeService.addMenu(menu)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.menu.add.success.title"
                                                       defaultMessage="Menu Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.menu.add.success.content"
                                                         defaultMessage="Menu created successfully"/>
                                
                    dispatch(alertActions.success({title: "recipe.actions.menu.add.success.title",
                                                   content:"recipe.actions.menu.add.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/menus');
                } 
            );
    };

    function request() { return { type: recipeConstants.ADD_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.ADD_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.ADD_MENU_FAILURE, error } }
}


function updateMenu(id,menu,link) {
    return dispatch => {
        dispatch(request());

        recipeService.updateMenu(id,menu)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.menu.update.success.title"
                                                       defaultMessage="Menu Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.menu.update.success.content"
                                                         defaultMessage="Menu updated successfully"/>
                                
                    dispatch(alertActions.success({title: "recipe.actions.menu.update.success.title",
                                                   content:"recipe.actions.menu.update.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/editmenu/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.UPDATE_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_MENU_FAILURE, error } }
}



function deleteMenu(id) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteMenu(id)
            .then(
                recipes => { 
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.menu.delete.success.title"
                                                       defaultMessage="Menu Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.menu.delete.success.content"
                                                          defaultMessage="Menu deleted successfully"/>
                    dispatch(alertActions.success({title:'recipe.actions.menu.delete.success.title',
                                                   content:'recipe.actions.menu.delete.success.content'}));
                    //dispatch(alertActions.clear());
                    history.push('/');
                    history.push('/menus');
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');
                    history.push('/menus');
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_MENU_FAILURE, error } }
}


function addRecipeMenu(id,recipe,link) {
    return dispatch => {
        dispatch(request());

        recipeService.addRecipeMenu(id,recipe)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/editmenu/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.ADD_RECIPE_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.ADD_RECIPE_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.ADD_RECIPE_MENU_FAILURE, error } }
}

function updateRecipeMenu(id,recipe/*,sorting*/,link) {
    return dispatch => {
        dispatch(request());
        recipeService.updateRecipeMenu(id,recipe)
            .then(
                recipes =>{
                    //recipes = [recipes, sorting]
                    dispatch(success(recipes));
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/editmenu/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.UPDATE_RECIPE_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_RECIPE_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_RECIPE_MENU_FAILURE, error } }
}


function deleteRecipeMenu(id,recipe,link) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteRecipeMenu(id,recipe)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.menu.delete.recipe.success.title"
                                                       defaultMessage="Menu Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.menu.delete.recipe.success.content"
                                                         defaultMessage="Recipe delete in menu successfully"/>
                                
                    dispatch(alertActions.success({title: "recipe.actions.menu.delete.recipe.success.title",
                                                   content:"recipe.actions.menu.delete.recipe.success.content"}));
                       
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/editmenu/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.DELETE_RECIPE_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_RECIPE_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_RECIPE_MENU_FAILURE, error } }
}


function addIngredientMenu(id,ingredient,link) {
    return dispatch => {
        dispatch(request());

        recipeService.addIngredientMenu(id,ingredient)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/editmenu/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.ADD_INGREDIENT_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.ADD_INGREDIENT_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.ADD_INGREDIENT_MENU_FAILURE, error } }
}

function updateIngredientMenu(id,ingredient,link) {
    return dispatch => {
        dispatch(request());
        recipeService.updateIngredientMenu(id,ingredient)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/editmenu/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.UPDATE_INGREDIENT_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_INGREDIENT_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_INGREDIENT_MENU_FAILURE, error } }
}


function deleteIngredientMenu(id,ingredient,link) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteIngredientMenu(id,ingredient)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="recipe.actions.menu.delete.ingredient.success.title"
                                                       defaultMessage="Menu Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.menu.delete.ingredient.success.content"
                                                         defaultMessage="Ingredient delete in menu successfully"/>
                                
                    dispatch(alertActions.success({title: "recipe.actions.menu.delete.ingredient.success.title",
                                                   content:"recipe.actions.menu.delete.ingredient.success.content"}));
                       
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/editmenu/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.DELETE_INGREDIENT_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_INGREDIENT_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_INGREDIENT_MENU_FAILURE, error } }
}



function downloadMenu(parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.downloadMenu(parameters)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.DOWNLOAD_MENU_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DOWNLOAD_MENU_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DOWNLOAD_MENU_FAILURE, error } }
}


function countMenus() {
    return dispatch => {
        dispatch(request());

        recipeService.countMenus()
            .then(
                recipes => dispatch(success(recipes)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.COUNT_MENUS_REQUEST } }
    function success(recipes) { return { type: recipeConstants.COUNT_MENUS_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.COUNT_MENUS_FAILURE, error } }
}


function allergensMatrix(all,recipes, ingredients) {
    return dispatch => {
        dispatch(request());

        recipeService.allergensMatrix(all,recipes, ingredients)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.DOWNLOAD_ALLERGENS_MATRIX_SELECT_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DOWNLOAD_ALLERGENS_MATRIX_SELECT_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DOWNLOAD_ALLERGENS_MATRIX_SELECT_FAILURE, error } }
}

function getAllergensRecipes() {
    return dispatch => {
        dispatch(request());

        recipeService.getAllergensRecipes()
            .then(
                recipes => dispatch(success(recipes)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GETALL_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GETALL_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GETALL_FAILURE, error } }
}

function getOrdersRecipes(start,end,sequence) {
    return dispatch => {
        dispatch(request());

        recipeService.getOrdersRecipes(start,end)
            .then(
                recipes => dispatch(success(recipes,sequence)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GETALL_FOR_ORDER_REQUEST } }
    function success(recipes,sequence) { return { type: recipeConstants.GETALL_FOR_ORDER_SUCCESS,recipes,sequence }}
    function failure(error) { return { type: recipeConstants.GETALL_FOR_ORDER_FAILURE, error } }
}


function inventory(date) {
    return dispatch => {
        dispatch(request());

        recipeService.inventory(date)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    history.push({pathname:'/inventory',state:{startPage:1}});
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.INVENTORY_REQUEST } }
    function success(recipes) { return { type: recipeConstants.INVENTORY_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.INVENTORY_FAILURE, error } }
}


function update_stock(id,quantity,page) {
    return dispatch => {
        dispatch(request());

        recipeService.update_stock(id,quantity)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    //history.push('/');
                    //history.push({pathname:'/inventory',state:{startPage:page}});
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    //history.push('/');
                    //history.push({pathname:'/inventory',state:{startPage:page}});
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_STOCK_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_STOCK_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_STOCK_FAILURE, error } }
}


function reset_stock() {
    return dispatch => {
        dispatch(request());

        recipeService.reset_stock()
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    history.push('/');
                    history.push({pathname:'/inventory',state:{startPage:1}});
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    //history.push('/');
                    //history.push({pathname:'/inventory',state:{startPage:1}});
                }
            );
    };

    function request() { return { type: recipeConstants.RESET_STOCK_REQUEST } }
    function success(recipes) { return { type: recipeConstants.RESET_STOCK_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.RESET_STOCK_FAILURE, error } }
}


function updateDaysValidity(id,number_of_days) {
    return dispatch => {
        dispatch(request());

        recipeService.updateDaysValidity(id,number_of_days)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    //history.push('/');
                    //history.push({pathname:'/inventory',state:{startPage:page}});
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    //history.push('/');
                    //history.push({pathname:'/inventory',state:{startPage:page}});
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_VALIDITY_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_VALIDITY_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_VALIDITY_FAILURE, error } }
}



function categorySelect(id,link) {
    return dispatch => {
        dispatch(request(id));

        recipeService.categorySelect(id)
            .then(
                ingredients => { 
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="recipes.actions.recipe.category.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipes.actions.recipe.category.success.content"
                                                          defaultMessage="Recipe(s) updated successfully"/>
                    dispatch(alertActions.success({title:'recipes.actions.recipe.category.success.title',
                                                   content:'recipes.actions.recipe.category.success.content'}));
                     if (link){
                           history.push('/');
                           history.push(link);
                     }
                },
                error => {
  
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                        content:error.message,
                                                        stringMode: true }));

                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                }
            );
    };

    function request() { return { type: recipeConstants.CATEGORY_ALL_REQUEST } }
    function success(ingredients) { return { type: recipeConstants.CATEGORY_ALL_SUCCESS, ingredients } }
    function failure(error) { return { type: recipeConstants.CATEGORY_ALL_FAILURE, error } }
}


function getEstimate(id) {
    return dispatch => {
        dispatch(request());

        recipeService.getEstimate(id)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_ESTIMATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_ESTIMATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_ESTIMATE_FAILURE, error } }
}

function getEstimates(short=false) {
    return dispatch => {
        dispatch(request());

        recipeService.getEstimates(short)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_ESTIMATES_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_ESTIMATES_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_ESTIMATES_FAILURE, error } }
}


function saveEstimate(properties,silent=false) {
    return dispatch => {
        dispatch(request());

        recipeService.saveEstimate(properties)
            .then(
                recipes => {
                    dispatch(success(recipes));
                    
                    if (silent === false){
                            let title_text = <FormattedMessage id="invoices.actions.estimate.save.success.title"
                                                        defaultMessage="Estimate Action"/>
                            let content_text = <FormattedMessage id="invoices.actions.estimate.save.success.content"
                                                        defaultMessage="Estimate Saved Updated Successfully"/>
                            dispatch(alertActions.success({title:'invoices.actions.estimate.save.success.title',
                                                          content:'invoices.actions.estimate.save.success.content'}));
                            history.push('/estimates');
                            //history.push('/orderdetails/'+recipes.id+'/');
                        }
                },
                error => {
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/estimates');
                }
            );
    };

    function request() { return { type: recipeConstants.SAVE_ESTIMATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.SAVE_ESTIMATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.SAVE_ESTIMATE_FAILURE, error } }
}


function updateEstimate(id,parameters, silent=false) {
    return dispatch => {
        dispatch(request());

        recipeService.updateEstimate(id,parameters)
            .then(
                recipes => {
                    
                    dispatch(success(recipes))
                    if (silent == false){
                            let title_text = <FormattedMessage id="invoices.actions.estimate.update.success.title"
                                                        defaultMessage="Estimate Action"/>
                            let content_text = <FormattedMessage id="invoices.actions.estimate.update.success.content"
                                                                defaultMessage="Estimate updated successfully"/>
                            dispatch(alertActions.success({title:'invoices.actions.estimate.update.success.title',
                                                    content:'invoices.actions.estimate.update.success.content'}));
                            history.push('/estimates');
                    }
                },
                error => {
                        dispatch(failure(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                     content:error.message,
                                                     stringMode: true }));
                        history.push('/estimates');
                    }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_ESTIMATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_ESTIMATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_ESTIMATE_FAILURE, error } }
}


function updateEstimateInfos(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.updateEstimateInfos(id,parameters)
            .then(
                recipes => {
                    
                    dispatch(success(recipes))
                },
                error => {
                        dispatch(failure(error));
                    }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_ESTIMATE_INFOS_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_ESTIMATE_INFOS_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_ESTIMATE_INFOS_FAILURE, error } }
}


function billEstimate(id) {
    return dispatch => {
        dispatch(request());

        recipeService.billEstimate(id)
            .then(
                recipes => {
                    
                    dispatch(success(recipes))
                },
                error => {
                        dispatch(failure(error));
                    }
            );
    };

    function request() { return { type: recipeConstants.BILL_ESTIMATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.BILL_ESTIMATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.BILL_ESTIMATE_FAILURE, error } }
}



function deleteEstimate(id) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteEstimate(id)
            .then(
                recipes => {
                    dispatch(success(recipes));

                        let title_text = <FormattedMessage id="invoices.actions.estimate.delete.success.title"
                                                    defaultMessage="Estimate Action"/>
                        let content_text = <FormattedMessage id="invoices.actions.estimate.delete.success.content"
                                                    defaultMessage="Estimate Deleted Successfully"/>
                        dispatch(alertActions.success({title:'invoices.actions.estimate.delete.success.title',
                                                content:'invoices.actions.estimate.delete.success.content'}));
                        history.push('/');
                        history.push('/estimates');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/estimates');
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_ESTIMATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_ESTIMATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_ESTIMATE_FAILURE, error } }
}


function updateEstimateRecipeQuantity(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.updateEstimateRecipeQuantity(id,parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                    history.push('/');
                    history.push('/estimatedetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/estimatedetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_QUANTITY_ESTIMATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_QUANTITY_ESTIMATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_QUANTITY_ESTIMATE_FAILURE, error } }
}


function deleteRecipeEstimate(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteRecipeEstimate(id,parameters)
            .then(
                recipes => {
                    dispatch(success(recipes))
                    history.push('/');
                    history.push('/estimatedetails/'+id+'/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/estimatedetails/'+id);
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_RECIPE_ESTIMATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_RECIPE_ESTIMATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_IRECIPE_ESTIMATE_FAILURE, error } }
}


function addEstimateRecipe(id,parameters) {
    return dispatch => {
        dispatch(request());

        recipeService.addEstimateRecipe(id,parameters)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    //history.push('/');
                    //history.push('/estimatedetails/'+id+'/');
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                   //history.push('/');
                   //history.push('/estimatedetails/'+id);
                } 
            );
    };

    function request() { return { type: recipeConstants.ADD_RECIPE_ESTIMATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.ADD_RECIPE_ESTIMATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.ADD_RECIPE_ESTIMATE_FAILURE, error } }
}

/* 
function sendPOS(recipes) {
    return dispatch => {
        dispatch(request());

        recipeService.sendPOS(recipes)
            .then(
                recipes => {
                    dispatch(success(recipes))
                    let title_text = <FormattedMessage id="recipes.actions.pos.send.success.title"
                                                    defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipes.actions.pos.send.success.content"
                                                            defaultMessage="Recipes sent successfully"/>
                    dispatch(alertActions.success({title:'recipes.actions.pos.send.success.title',
                                                  content:'recipes.actions.pos.send.success.content'}));
                
                     
                    history.push('/');
                    history.push('/recipes/');
                },
                error => {
                        dispatch(failure(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                     content:error.message,
                                                     stringMode: true }));
                        history.push('/');
                        history.push('/recipes/');
                    }
            );
    };

    function request() { return { type: recipeConstants.SEND_POS_REQUEST } }
    function success(recipes) { return { type: recipeConstants.SEND_POS_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.SEND_POS_FAILURE, error } }
} */


function renderEstimate(properties) {
    return dispatch => {
        dispatch(request());

        recipeService.renderEstimate(properties)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_ORDER_PDF_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_ORDER_PDF_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_ORDER_PDF_FAILURE, error } }
}


function deleteSelectEstimate(estimates) {
    return dispatch => {
        dispatch(request());

        recipeService.deleteSelectEstimate(estimates)
            .then(
                recipes => { 
                    dispatch(success(recipes));
                    let title_text = <FormattedMessage id="invoices.actions.invoices.deleteselect.success.title"
                                                       defaultMessage="Estimate Action"/>
                    let content_text = <FormattedMessage id="invoices.actions.invoices.deleteselect.success.content"
                                                          defaultMessage="Estimate(s) Deleted Successfully"/>
                    dispatch(alertActions.success({title:'invoices.actions.invoices.deleteselect.success.title',
                                                   content:'invoices.actions.invoices.deleteselect.success.content'}));
                    //dispatch(alertActions.clear());
                    history.push('/');
                    history.push('/estimates');
                },
                error => {


                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));

                    history.push('/');
                    history.push('/estimates');
                }
            );
    };

    function request() { return { type: recipeConstants.DELETE_ESTIMATE_REQUEST } }
    function success(recipes) { return { type: recipeConstants.DELETE_ESTIMATE_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.DELETE_ESTIMATE_FAILURE, error } }
}


function updateStateSelectOrder(orders,state) {
    return dispatch => {
        dispatch(request());

        recipeService.updateStateSelectOrder(orders,state)
            .then(
                recipes => { 
                    dispatch(success(recipes));

                    //dispatch(alertActions.clear());
                    let title_text = <FormattedMessage id="productions.actions.production.state.success.title"
                                                    defaultMessage="Production Action"/>
                    let content_text = <FormattedMessage id="productions.actions.production.state.success.content"
                                                    defaultMessage="Production(s) Updated Successfully"/>
                    dispatch(alertActions.success({title:'productions.actions.production.state.success.title',
                                                       content:'productions.actions.production.state.success.content'}));
                    history.push('/');
                    history.push('/productions');
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));

                }
            );
    };

    function request() { return { type: recipeConstants.UPDATE_PROD_STATE_ORDER_REQUEST } }
    function success(recipes) { return { type: recipeConstants.UPDATE_PROD_STATE_ORDER_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.UPDATE_PROD_STATE_ORDER_FAILURE, error } }
}


function countEstimates() {
    return dispatch => {
        dispatch(request());

        recipeService.countEstimates()
            .then(
                recipes => dispatch(success(recipes)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.COUNT_ESTIMATES_REQUEST } }
    function success(recipes) { return { type: recipeConstants.COUNT_ESTIMATES_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.COUNT_ESTIMATES_FAILURE, error } }
}


function sendEstimate(properties) {
    return dispatch => {
        dispatch(request());

        recipeService.sendEstimate(properties)
            .then(
                recipes => {
                    dispatch(success(recipes));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: recipeConstants.GET_ORDER_PDF_REQUEST } }
    function success(recipes) { return { type: recipeConstants.GET_ORDER_PDF_SUCCESS, recipes } }
    function failure(error) { return { type: recipeConstants.GET_ORDER_PDF_FAILURE, error } }
}
