import React, { Component } from 'react';
import { Table, Menu, Header, Grid, Button,Form,Pagination,Modal,Input,Dropdown,Dimmer,Loader,Icon,Message,Image,Breadcrumb,Segment,Label} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {paymentActions, saleActions,userActions} from '../_actions'
import './Ingredients.css';
import SideLeftUncoverSales from './SidebarSales';
import {history,toaster_dispatch,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER,isFloat,isNumeric} from '../_helpers';
import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,injectIntl} from 'react-intl';
import {isEmpty,deburr} from "lodash"
import {styles} from './Styles';
import img_register from '../Assets/cash-register_blue.png'
import img_performance from '../Assets/performance.png'
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  'react-datepicker';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';

var HOUR_TIME=60*60*1000;

const options = [
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
  ]


class Sales extends Component {
    constructor(props) {
        super(props);
        const {intl} = props;
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { prevSales : props.sales,
                       activeModal: null,
                       errorNotification: false,
                       filtering: false,
                       currency : userIn.user.currency ,
                       toggled:false,
                       isMobile: false,
                       page: 1,
                       itemsPerPage: 10,
                       selectionList:[],
                       actionSelected: "",
                       actionModal: false,
                       allowMultipleSelection:true,
                       typeSelected:'Category'
                    }

        this.hideModal = this.hideModal.bind(this);
        this.filterNameList=this.filterNameList.bind(this);
        this.selectActionList=this.selectActionList.bind(this);
        this.removeFilters=this.removeFilters.bind(this);
        this.cancelAction=this.cancelAction.bind(this);
        this.setPageNum = this.setPageNum.bind(this);
        this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
        //this.updateFromPOS = this.updateFromPOS.bind(this);
        this.openInventory = this.openInventory.bind(this);

        props.dispatch(saleActions.getAll());
        props.dispatch(paymentActions.getSubscriptionState());
        //props.dispatch(userActions.getPOS());

        registerLocale('en', en)
        registerLocale('fr', fr)
        if(intl.locale == 'fr'){
            setDefaultLocale('fr',fr)
        }else{
            setDefaultLocale('en',en)
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    static getDerivedStateFromProps(props, state) {
       if((props.sales !==state.prevSales) && (props.sales.items)) {
           const sales = props.sales;
           //let sellTotalValue = 0; 
           let sellTotalYearValue = 0; 
           let sellMonthValue = 0; 
           var currentdate = new Date();
           var current_year = currentdate.getFullYear();
           var current_month = currentdate.getMonth() + 1;
           sales.items.map((i,index) => {
               var sale_month =  new Date(i.sale_date).getMonth() + 1;
               var sale_year  =  new Date(i.sale_date).getFullYear();
               if (i && i.price){
                   //sellTotalValue+=Number(i.price);
                   if (sale_year == current_year){
                      sellTotalYearValue +=Number(i.price);
                   }
               }
               if (i && i.price && sale_month == current_month  && sale_year == current_year){
                 sellMonthValue+=Number(i.price);
               }
           });
           return {prevSales : props.sales,
                   //sellTotalValue:Math.round(sellTotalValue * 100) / 100,
                   sellTotalYearValue:Math.round(sellTotalYearValue * 100) / 100,
                   sellMonthValue:Math.round(sellMonthValue * 100) / 100}
       }
       return null;
    }
    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
        this.resetSelectionList();
        this.setState({isCheckedAll: false});
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }

    removeFilters(event){
        event.preventDefault();
        this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
        this.setState({ selectedStartDate: null, selectedEndDate: null})
    }

    filterNameList(event){
        event.preventDefault();

        if (event.target.value == ""){
            this.removeFilters(event);
            return;
        }
/*         let updatedList = this.props.sales.items.filter((item) => {
          return (item.recipe_name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                        .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
        }); */

        if(this.props.sales.items){
        let words = (event.target.value).normalize('NFKD').toLowerCase().split(' ')
        words = words.map(word => deburr(word))
        //words = words.filter(item => item.length > 1);
        //words = words.filter(item => item);
        let updatedList = this.props.sales.items.filter((item) => {
                  let clean_item_name = deburr(item.recipe_name)
                  return words.some(word => (clean_item_name).normalize('NFKD').toLowerCase().includes(word))
        });
        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: event.target.value});
         this.setState({ selectionList:[]});
         this.setState({ selectedStartDate: null, selectedEndDate: null})
        }
    }

    selectActionList(event,data){
        event.preventDefault();
        const { selectionList } = this.state;
        this.setState({actionSelected: data.value});
/*         if (selectionList.length > 0 && data.value === 'updatestock'){
            this.setState({actionModalUpdateStock: true});
        } */
        if (selectionList.length > 0 && data.value === 'delete'){
            this.setState({actionModal: true});
        }
    }

    cancelAction(event){
        event.preventDefault();
        this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
        this.setState({ selectedStartDate: null, selectedEndDate: null})
    }

    hideModal() {
        this.setState({ activeModal: null })
        this.setState({ actionModal: false })
        this.setState({ actionModalUpdateStock: false })
        //this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
        this.setState({ selectedStartDate: null, selectedEndDate: null})
    }

    seeRecipe (event,index) {
        event.preventDefault();
        //this.props.dispatch(ingredientsActions.get(index,'/ingredientdetails'));
        history.push('/recipedetails/'+index);
    }

    actionSelectDelete(event) {
        event.preventDefault();
        const { selectionList } = this.state;
        this.props.dispatch(saleActions.deleteSelect(selectionList,'/ingredients'))
        this.setState({ selectionList:[]});
    }

    actionSelectUpdateStock = (event) => {
/*         event.preventDefault();
        const { selectionList,storageAreaSelected } = this.state;

        const params = {
            ids: selectionList,
            elem_set: storageAreaSelected?true:false,
            elem_id: storageAreaSelected?storageAreaSelected.id:-1,
        }
        this.props.dispatch(ingredientsActions.stockAreaSelect(params,'/ingredients'))
        console.log(params)
        this.setState({ selectionList:[]}); */
    }

    setDateRange (update){
        const {sales} = this.props;
        this.setState({ selectionList:[]});
        this.setState({ selectedStartDate: update[0], selectedEndDate: update[1]})

        if (sales && update[0]!=null && update[1]!=null){
            let startDate = new Date(update[0])
            let endDate = new Date(update[1])
            endDate.setDate(endDate.getDate() + 1);
            let updatedList = sales.items.filter((item) => {
                    return new Date(item.sale_date) >= startDate && new Date(item.sale_date) <= endDate
                  });
                
            this.setState({newItems: updatedList});
            this.setState({filtering: true});
            this.setState({nameSelected: ""});
        }else{
            this.setState({filtering: false});
            this.setState({nameSelected: ""});
        }

        return [update[0],update[1]]
    }


    addSelectList(event,index){
        const { selectionList } = this.state;
        if (event.target.checked){
            this.setState({ selectionList:[...selectionList,index]});
        }else{
            this.setState({ selectionList:selectionList.filter((item)=> item!=index)});
            if (selectionList.length <= 1){
                this.setState({actionSelected: ""});
            }
            this.setState({isCheckedAll: false});
        }
    }

    selectAllSelectList(event,items){
        let allList = []
        if (event.target.checked){
            items.map((ingredient,index) => {
                allList.push(ingredient.id);
            });
        }else{
            this.setState({actionSelected: ""});
        }
        this.setState({ isCheckedAll: event.target.checked});
        this.setState({ selectionList:allList});
        
    }

    isChecked(index){
        const { selectionList } = this.state;
        return selectionList.includes(index)
    }

    isCheckedAll(){
        return this.state.isCheckedAll
    }

    resetSelectionList(){
        this.setState({ selectionList:[]});
        this.setState({ actionSelected: ""});
    }

    toaster(dispatch,alert){
        const { intl } = this.props;
        //this.setState({ errorNotification: false })
        this.setState({ activeModal: null });
        toaster_dispatch(dispatch,alert,intl);
    }

    assertPriceField(price){
        if ((isFloat(price) || isNumeric(price))
          && (price != "")
          && Math.trunc(price).toString().length<10)
          return true;
        return false;
    }
    

    addSalesRows(sales){
        const {currency,allowMultipleSelection} = this.state;
        return sales.map((sale,index) => {
                return (
                    <Table.Row key={index}>
                         <Table.Cell width={3} style={{marginLeft:'0.7em'}}>
                         {allowMultipleSelection && <Input type='checkbox' id={index}
                                   onChange={(e) => this.addSelectList(e,sale.id)}
                                   checked={this.isChecked(sale.id)}
                                   style={{'transform': 'scale(1.1)'}}
                                  />}
                            <span>
                            {/* <Icon color='grey' style={{marginLeft:'0.7em'}}
                                  name='clock outline'/> */}
                              <span style={{marginLeft:'0.7em'}}>
                                 <FormattedDate value={new Date(sale.sale_date)}
                                                year='numeric'
                                                month='short'
                                                day='2-digit'/> {/*{' - '}
                                  <FormattedTime value={new Date(sale.sale_date)}
                                                 hour='numeric'
                                                 minute='numeric'/> */}</span>
                            </span>
                        </Table.Cell>
                        <Table.Cell width={3}>
                            <span /* style={{marginLeft:'0.7em'}}*/> 
                            <Icon name='food' color='blue'/>
                            <a href={"/recipedetails/"+sale.recipe_id} 
                               onClick={(event) => this.seeRecipe(event,sale.recipe_id)}
                               > {sale.recipe_name && sale.recipe_name.length >= 45 && 
                                sale.name.substring(0,45) + '..'}
                                {sale.recipe_name && sale.recipe_name.length < 45 && 
                                sale.recipe_name}
                            </a>&nbsp;&nbsp;{sale.recipe_ref}
                            &nbsp; &nbsp;{((new Date() - new Date(sale.sale_date)) < HOUR_TIME) &&
                                <Label color='teal' size='small'><FormattedMessage id="sales.sale.status.new"
                                                                      defaultMessage='New'/></Label>}</span>
                            {/* <p style={{'color':'#b5b1b1',marginLeft:'2em'}}><i>{sale.recipe_ref}</i></p> */}
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                           <FormattedNumber value={sale.quantity}/>
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='right'>
                           <FormattedNumber value={sale.price_unit} style="currency" currencyDisplay="symbol" currency={currency}/>
                        </Table.Cell>
                        {/* <Table.Cell width={1} textAlign='right'><FormattedNumber value={Number(sale.price_unit)*Number(sale.quantity)} maximumFractionDigits="3"  style="currency" currencyDisplay="symbol" currency={currency}/></Table.Cell> */}
                        <Table.Cell width={1} textAlign='right'><FormattedNumber value={Number(sale.price)} maximumFractionDigits="3"  style="currency" currencyDisplay="symbol" currency={currency}/></Table.Cell>
                        <Table.Cell width={1} textAlign='right'>
                            {sale.vat > 0 &&
                           <FormattedNumber value={Number(sale.vat)} style="percent" maximumFractionDigits="3"/>}
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

/*     updateFromPOS(){
        this.props.dispatch(saleActions.retrieveFromPos());
    } */

    openInventory(){
        history.push({pathname:'/inventory',state:{typeStock:'recipe'}});
    }

    loadFromFile(){
        history.push('/importsales')
    }
    
    render() { 
        const { sales/* ,pos */,alert,dispatch,loading_s,loading,storageAreas,updating,intl } = this.props;
        const { newItems,filtering,nameSelected,page,itemsPerPage,
                selectionList,isCheckedAll,actionSelected ,allowMultipleSelection,storageAreaSelected,
                sellTotalYearValue,sellMonthValue,currency,selectedStartDate,selectedEndDate} = this.state;
        
        let totalPages = 1;
        let items = [];
        let length = 0;
        let begin = 0;
        let end  = 0;

        if (!filtering && sales && sales.items){
            length = sales.items.length;
            totalPages = Math.ceil( length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = sales.items.slice(begin,end);
        }
        if (filtering && newItems ){
            length = newItems.length;
            totalPages = Math.ceil(length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            if (begin > length) begin=0;
            items = newItems.slice(begin,end);
        }
        //Filter and notify restrictions if user unsubscribed
        if (this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER);
        }

        return ( 
            <div>
                 <SideLeftUncoverSales>
                        <Grid stackable style={styles.FontLato13Border}>
                          <Grid.Row columns={1}>
                            <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="sales.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="sales.link.sales"
                                                              defaultMessage='Sales'/></Breadcrumb.Section>
                            </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1} style={{paddingTop:'0em',marginTop:'Oem'}}>
                                <Grid.Column width={8}>
                                    <Header as='h2'>
                                        <img src={img_register} style={{width:'50px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="sales.page.title"
                                                              defaultMessage='Sales'/>
                                           <Header.Subheader><FormattedMessage id="sales.page.subtitle"
                                                              defaultMessage='Load your sales transactions'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                    </Grid.Column >
                            </Grid.Row> 
                            <Grid.Row columns={3} width='equals' stretched style={{paddingTop:'0em',marginTop:'Oem'}}>
                                <Grid.Column mobile={6} tablet={6} computer={6}/> 
                                <Grid.Column mobile={6} tablet={5} computer={4}/> 
                                <Grid.Column mobile={4} tablet={5} computer={4}> 
                                    <Segment style={{'background-color':'#e8f3fa', textAlign:'right'}} >
                                        <Header as='h3' color='grey'>
                                            <FormattedMessage id="sales.total.value.title"
                                                              defaultMessage='AMOUNT OF SELLS (this year)'/><br/>
                                            <FormattedNumber value={sellTotalYearValue} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                        </Header>
{/*                                         <span style={{fontSize:'14px', color:'#00b3b3'}}>
                                            <FormattedMessage id="sales.current.year.value.title"
                                                             defaultMessage='Current year'/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                             <i><FormattedNumber value={sellTotalYearValue} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                            </i>
                                        </span><br/> */}
                                        <span style={{fontSize:'16px', color:'#00b3b3'}}>
                                            <FormattedMessage id="sales.month.value.title"
                                                             defaultMessage='Current month'/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                             <i><FormattedNumber value={sellMonthValue} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                            </i>
                                        </span>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                             {alert.message && this.toaster(dispatch,alert)}
                            <Grid.Row columns={3} style={{paddingTop:'0em',marginTop:'Oem'}}>
                                <Grid.Column width={4}> 
                                    <p style={styles.FontLato13}><FormattedMessage id="sales.filter.name.title"
                                                         defaultMessage='Search'/></p>
                                    <FormattedMessage id="sales.filter.byname.placeholder"
                                                        defaultMessage='Type at least 2 letters'>
                                        {placeholder =>                                                       
                                            <Input //size='large'
                                                type="text"
                                                icon
                                                placeholder={placeholder}
                                                value={nameSelected}
                                                onChange={this.filterNameList}
                                                disabled={this.isUnSubscribedUser()}
                                                style={{minWidth:'25em',maxWidth:'30em', minHeight:'3em'}}><Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                    </FormattedMessage>
                                </Grid.Column>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <Grid.Column mobile={5} tablet={3} computer={3}>   
                                     <div width={4} style={{paddingTop:'0.5em',paddingLeft:'0.5em', fontSize:'16px'/* ,'color': '#66b2b2' */}}>
                                                            <p style={{paddingTop:'0em', fontSize:'13px'}} ><Icon name='calendar alternate outline'/>
                                                                    {' '}<FormattedMessage id="sales.filter.daterange.title"
                                                                                            defaultMessage='Sales date start/end'/>
                                                            </p>
                                                            <FormattedMessage id="sales.filter.daterange.placeholder"
                                                                                    defaultMessage='select a range'>
                                                                        {placeholder =>    
                                                                            <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={selectedStartDate}
                                                                                    endDate={selectedEndDate}
                                                                                    onChange={(update) => {
                                                                                        this.setDateRange(update);
                                                                                    }}
                                                                                    isClearable={true}
                                                                                    placeholderText = {placeholder} 
                                                                                    locale={intl.locale}
                                                                                    dateFormat="P"/>
                                                                        }
                                                            </FormattedMessage>
                                        </div>
                                </Grid.Column> 
                                <Grid.Column width={5}/>
                            </Grid.Row>
                            <Grid.Row style={{paddingTop:'0em',marginTop:'Oem'}}>
                                <Grid.Column mobile={10} tablet={10} computer={8}/> 
                                <Grid.Column width={6} textAlign="right"> 
                                    {!loading_s && 
                                           <Button color='grey' circular size='small'
                                            onClick={this.openInventory} >
                                            <Icon name='warehouse' />{' '}
                                            <FormattedMessage id="sales.inventory.open.button"
                                                                defaultMessage='Browse inventory'/>
                                        </Button>}
                                    {loading_s && 
                                           <Button color='grey' circular size='small'
                                            loading
                                            onClick={this.openInventory}>
                                            <Icon name='warehouse' />{' '}
                                            <FormattedMessage id="sales.inventory.open.button"
                                                                defaultMessage='Browse inventory'/>
                                        </Button>}
                                    {loading_s && <Button color='blue' circular size='small'
                                            loading
                                            onClick={this.loadFromFile}>
                                            <Icon name='cloud upload'/>{' '}
                                            <FormattedMessage id="sales.file.retrieve.button"
                                                                defaultMessage='Load from file'/>
                                        </Button>}
                                        {!loading_s && <Button color='blue' circular size='small'  
                                            onClick={this.loadFromFile}>
                                            <Icon name='cloud upload'/>{' '}
                                            <FormattedMessage id="sales.file.retrieve.button"
                                                                defaultMessage='Load from file'/>
                                        </Button>}
{/*                                     {loading_s && <Button color='teal' circular size='small'
                                            loading
                                            onClick={this.updateFromPOS}>
                                            <Icon name='tv' />
                                            <FormattedMessage id="sales.pos.retrieve.button"
                                                                defaultMessage='Update from POS'/>
                                        </Button>}
                                        {!loading_s && <Button color='teal' circular size='small'  icon='tv' 
                                            onClick={this.updateFromPOS}>
                                            <Icon name='tv' />
                                            <FormattedMessage id="sales.pos.retrieve.button"
                                                                defaultMessage='Update from POS'/>
                                        </Button>}
                                         <br/>
                                        Last update : {' '}
                                        {pos && <FormattedTime value={new Date(pos[0].synchro_date)}
                                                    year='numeric'
                                                    month='short'
                                                    day='2-digit'/> } */}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} /* style={{'overflow-x': 'auto'}} */style={{paddingTop:'0em',marginTop:'Oem'}}>
                              <Grid.Column /*width={14}*/  mobile={18} tablet={16} computer={14}>
                                  {selectionList.length > 0 && 
                                     <FormattedMessage id="sales.action.main.placeholder"
                                                       defaultMessage='{number} selected'
                                                       values={{number:selectionList.length}}>
                                        {placeholder =>  
                                                <Dropdown selection floating labeled
                                                        options= {[/* { key: 'updatestock', icon: {name:'warehouse',color:'grey'},
                                                                     text: <FormattedMessage id="sales.action.updatestock.text"
                                                                                            defaultMessage='Update stock'/>, value: 'updatestock' }, */
                                                                   { key: 'delete', icon: {name:'trash alternate outline',color:'red'},
                                                                     text: <FormattedMessage id="sales.action.delete.text"
                                                                                            defaultMessage='Delete'/>, value: 'delete' },]}
                                                        value= {actionSelected}
                                                        text={placeholder}
                                                        className='icon'
                                                        onChange={this.selectActionList}
                                                        style={{'font-size':'13px','letter-spacing': '0.025em',
                                                                'background-color':'#66b2b2',color:'white'}}/>}
                                    </FormattedMessage>}
                                        {selectionList.length > 0 &&
                                            <Button compact 
                                                className="ui icon button"
                                                //color='grey' 
                                                style={{marginLeft:'0.3em' ,'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#bfbfbf',color:'white'}}
                                                onClick={this.cancelAction}>
                                               <FormattedMessage id="sales.action.button.cancel.text"
                                                                   defaultMessage='Cancel'/>
                                        </Button>}
                                <Dimmer.Dimmable> 
                                        <Dimmer active={loading} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer>
                                 <Table    unstackable 
                                           compact 
                                           striped 
                                           singleLine 
                                           style={styles.FontLato13Border} >
                                        <Table.Header>
                                            <Table.Row>
                                                {/* <Table.HeaderCell width={0} style={{width:'0.2em'}} ><Checkbox/></Table.HeaderCell> */}
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    {allowMultipleSelection && <Input type='checkbox'
                                                            onChange={(e) => this.selectAllSelectList(e,items)}
                                                            style={{'transform': 'scale(1.1)'}}
                                                            checked={isCheckedAll}/>}&nbsp;&nbsp; 
                                                        <FormattedMessage id="sales.table.header.column.date"
                                                                            defaultMessage='Date'/>
                                                 </Table.HeaderCell>
                                                 <Table.HeaderCell width={3} textAlign='left'>
                                                       <span /* style={{marginLeft:'0.7em'}} */><FormattedMessage id="sales.table.header.column.name"
                                                                             defaultMessage='Name'/>{' '}<span style={{'color':'#b5b1b1','font-size':'12px',marginLeft:'1em'}}><FormattedMessage id="sales.table.header.column.reference"
                                                                             defaultMessage='Reference code'/></span></span>
                                                      {/* <p style={{'color':'#b5b1b1','font-size':'12px',marginLeft:'1em'}}><i><FormattedMessage id="sales.table.header.column.reference"
                                                                          defaultMessage='Reference code'/></i></p> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="sales.table.header.column.units"
                                                                          defaultMessage='Units '/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="sales.table.header.column.unitprice"
                                                                          defaultMessage='Unit price'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="sales.table.header.column.total"
                                                                          defaultMessage='Total'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="sales.table.header.column.vat"
                                                                          defaultMessage='Sales tax '/>
                                                </Table.HeaderCell>
                                               {/*  <Table.HeaderCell width={2} textAlign='right'>
                                                </Table.HeaderCell>  */}
                                            </Table.Row>
                                        </Table.Header>     
                                        <Table.Body>
                                        {items && (items.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="sales.table.header.row.noitems"
                                                                            defaultMessage='No sales'/></Table.Cell>
                                                <Table.Cell colspan='7'></Table.Cell>
                                            </Table.Row>}
                                        {items && items.length > 0 &&
                                            this.addSalesRows(items)
                                        }
                                        </Table.Body>
                                        {this.isUnSubscribedUser() && items &&
                                        <Table.Footer fullWidth>
                                                <Table.HeaderCell colSpan='8'>
                                                <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                        <Image src={img_performance} size ='small' rounded/>
                                                        <br/>
                                                        <Message.Content style={{marginLeft:'1em'}}>
                                                            <Message.Header color='black'>
                                                                <FormattedMessage id="sales.premium.title.message.upgradeplan"
                                                                                    defaultMessage='Recipes limit exceeded'/> 
                                                            </Message.Header>
                                                            <br/>
                                                            {sales && sales.items && <div style={styles.FontLato13}>
                                                                <FormattedMessage id="sales.premium.message.upgradeplan.line1"
                                                                                  defaultMessage='Your account has'/>{' '}{sales.items.length}{' '}
                                                                <FormattedMessage id="sales.premium.message.upgradeplan.line2"
                                                                                  defaultMessage='recipes but your plan only permits'/>{' '}{MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER}{'.'} 

                                                            </div>}
                                                            <br/><br/>
                                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="sales.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                                            </Button>                                      
                                                        </Message.Content>
                                                    </Message>
                                                </Table.HeaderCell>
                                        </Table.Footer>}
                                </Table>
                                </Dimmer.Dimmable>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3} >
                                <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={10} 
                                                  options={options} 
                                                  item
                                                  inline
                                                  fluid
                                                  onChange={this.onElemPerPageChange}
                                                  disabled={this.isUnSubscribedUser()}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="sales.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="sales.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={10}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        //ellipsisItem={null}
                                        firstItem = {null} 
                                        lastItem = {null}
                                        onPageChange={this.setPageNum}
                                        disabled={this.isUnSubscribedUser()}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Modal open={this.state.actionModal === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="sales.modal.select.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="sales.modal.select.deletion"
                                                         defaultMessage='Really sure to delete the selected sell(s)?'
                                        /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="sales.modal.select.deletion.button.no"
                                                              defaultMessage='No'/>
                                    </Button>
                                    <Button style={styles.ButtonRed} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="sales.modal.select.deletion.button.yes"
                                                                    defaultMessage='Yes'/> }
                                        icon='checkmark'
                                        onClick={(event) => this.actionSelectDelete(event)}/>
                                    </Modal.Actions>
                        </Modal>
                        <Modal   open={this.state.actionModalUpdateStock}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                    <Header icon='warehouse' content={<FormattedMessage id="sales.form.updatestock.change.modal.title"
                                                                                    defaultMessage='Update stock with selected sells'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'1em',paddingBottom:'3em'}}>
                                          <Form.Field width={8}>
                                            <label><FormattedMessage id="sales.form.stockarea.name"
                                                                      defaultMessage='Update Stock/Inventory'/>
                                            </label>
                                            <FormattedMessage id="sales.form.stockarea.placeholder"
                                                              defaultMessage='Select'>
                                                  {placeholder =>   
                                                          <Form.Dropdown selection search //scrolling 
                                                                  options={storageAreas && storageAreas.options} 
                                                                  value = {storageAreaSelected && storageAreaSelected.id} 
                                                                  placeholder={placeholder} 
                                                                  onChange={this.onStorageAreaChange}
                                                                  style={{'font-size':'13px'}}
                                                                  clearable
                                                            />}
                                            </FormattedMessage>
                                          </Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="sales.form.stockarea.modal.validate.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button color='teal' loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="sales.form.stockarea.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.actionSelectStorageArea}
                                            >
                                        </Button>}
                                        {!updating && <Button color='teal'
                                            labelPosition='right' 
                                            content={<FormattedMessage id="sales.form.stockarea.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.actionSelectStorageArea}
                                            >
                                        </Button>}
                                    </Modal.Actions>
                        </Modal>
                </SideLeftUncoverSales>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { sales, dispatch, alert } = state;
    const { loading_s,loading,updating} = state.sales;
    const { subscription } = state.payment;
    //const { pos } = state.users;
    return {
        sales,
        //pos,
        dispatch,
        alert,
        loading,
        loading_s,
        updating,
        subscription,
    };
}

const connectedSales = injectIntl(connect(mapStateToProps)(Sales));
export { connectedSales as Sales };
 