export const saleConstants = {
    GETALL_REQUEST: 'SALES_GETALL_REQUEST',
    GETALL_SUCCESS: 'SALES_GETALL_SUCCESS',
    GETALL_FAILURE: 'SALES_GETALL_FAILURE',

    RETRIEVEALL_POS_REQUEST:'SALES_RETRIEVEALL_POS_REQUEST',
    RETRIEVEALL_POS_SUCCESS:'SALES_RETRIEVEALL_POS_REQUEST',
    RETRIEVEALL_POS_FAILURE:'SALES_RETRIEVEALL_POS_REQUEST',

    ADD_FROM_FILE_REQUEST:'SALES_ADD_FROM_FILE_REQUEST',
    ADD_FROM_FILE_SUCCESS:'SALES_ADD_FROM_FILE_SUCCESS',
    ADD_FROM_FILE_FAILURE:'SALES_ADD_FROM_FILE_FAILURE',

    DELETE_REQUEST: 'SALES_DELETE_REQUEST',
    DELETE_SUCCESS: 'SALES_DELETE_SUCCESS',
    DELETE_FAILURE: 'SALES_DELETE_FAILURE',

    
};