import { saleConstants } from '../_constants';
import { saleService } from '../_api';
import { alertActions } from './';
import { history,isEmpty } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const saleActions = {
    getAll,
    add,
    deleteSelect,
    addFromFile,
    retrieveFromPos
};

function getAll(short=false) {
    return dispatch => {
        dispatch(request());

        saleService.getAll(short)
            .then(
                sales => dispatch(success(sales)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: saleConstants.GETALL_REQUEST } }
    function success(sales) { return { type: saleConstants.GETALL_SUCCESS, sales } }
    function failure(error) { return { type: saleConstants.GETALL_FAILURE, error } }
}

function add(sell,link, isSameLink=0) {
    return dispatch => {
        dispatch(request());

        saleService.add(sell)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    //dispatch(alertActions.success({title:'Recipe Action',content:'Recipe Added Successfully'}));
/*                     let title_text = <FormattedMessage id="recipe.actions.recipe.add.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.add.success.content"
                                                         defaultMessage="Recipe Added Successfully"/>
                                
                    dispatch(alertActions.success({title: "recipe.actions.recipe.add.success.title",
                                                   content:"recipe.actions.recipe.add.success.content"})); */

                     if (link){
                        if (isSameLink) history.push('/');
                        history.push(link);
                    }else{
                        //history.push('/');
                        history.push('/recipes');
                    }
                },
                error => {
                    dispatch(failure(error));
/*                     let title_text = <FormattedMessage id="sales.actions.recipe.add.error.title"
                                                       defaultMessage="Recipe Action Error"/>

                    if ("yield_count" in error) {
                        let content_text = <FormattedMessage id="sales.actions.recipe.add.error.yield_count"
                                                             defaultMessage="yield_count: {message}"
                                                             values={{message:error.yield_count[0]}}/>
                        dispatch(alertActions.error({title: "sales.actions.recipe.add.error.title",
                                                     content:"recipe.actions.recipe.add.error.yield_count"}));


                     }else if ("name" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.name"
                                                            defaultMessage="name: {message}"
                                                            values={{message:error.name[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.name"}));
                     }else if ("yield_units" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.yieldunits"
                                                            defaultMessage="yield_units: {message}"
                                                            values={{message:error.yield_units[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.yieldunits"}));
                    }else if ("ingredients" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.ingredients"
                                                            defaultMessage="ingredients: {message}"
                                                            values={{message:error.ingredients[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.ingredients"}));
                    }else if ("subrecipes" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.subrecipes"
                                                            defaultMessage="subrecipes: {message}"
                                                            values={{message:error.recipes[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.subrecipes"}));
                    }else if ("category" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.category"
                                                            defaultMessage="category: {message}"
                                                            values={{message:error.category[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.category"}));
                    }else if ("non_field_errors" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.non_field_errors"
                                                            defaultMessage="{message}"
                                                            values={{message:error.non_field_errors[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.non_field_errors"}));
                    }else{
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                   stringMode: true }));
                    } */ 
                }

            );
    };

    function request() { return { type: saleConstants.ADD_REQUEST } }
    function success(recipes) { return { type: saleConstants.ADD_SUCCESS, recipes } }
    function failure(error) { return { type: saleConstants.ADD_FAILURE, error } }
}

function deleteSelect(sales) {
    return dispatch => {
        dispatch(request());

        saleService.deleteSelect(sales)
            .then(
                sales => { 
                    dispatch(success(sales));
                    let title_text = <FormattedMessage id="sales.actions.delete.select.success.title"
                                                       defaultMessage="Sales Action"/>
                    let content_text = <FormattedMessage id="sales.actions.delete.select.success.content"
                                                          defaultMessage="Sales(s) Deleted Successfully"/>
                    dispatch(alertActions.success({title:'sales.actions.delete.select.success.title',
                                                   content:'sales.actions.delete.select.success.content'}));
                    //dispatch(alertActions.clear());
                    history.push('/');
                    history.push('/sales');
                },
                error => {

                        dispatch(failure(error));
                        //dispatch(alertActions.error(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));
                        history.push('/');
                        history.push('/sales');
                    }
            );
    };

    function request() { return { type: saleConstants.DELETE_REQUEST } }
    function success(sales) { return { type: saleConstants.DELETE_SUCCESS, sales } }
    function failure(error) { return { type: saleConstants.DELETE_FAILURE, error } }
}

function addFromFile(sells,updateStock,link) {
    return dispatch => {
        dispatch(request());

        saleService.addBatch(sells,updateStock)
            .then(
                sales =>{
                    dispatch(success(sales));
                    let title_text = <FormattedMessage id="sales.actions.add.success.title"
                                                    defaultMessage="Sale Action"/>
                    let content_text = <FormattedMessage id="sales.actions.add.success.content"
                                                        defaultMessage="Sale Added Successfully"/>

                    dispatch(alertActions.success({title: "sales.actions.add.success.title",
                                                   content:"sales.actions.add.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    let title_text = <FormattedMessage id="sales.actions.add.error.title"
                                                       defaultMessage="Sale Action Error"/>

                    if ("name" in error) {
                        //dispatch(alertActions.error("name: " + error.name[0]));
                        let content_text = <FormattedMessage id="sales.actions.add.error.name"
                                                            defaultMessage="name:  {message}"
                                                            values={{message:error.name[0]}}/>
                        dispatch(alertActions.error({title: "sales.actions.add.error.title",
                                                     content:"sales.actions.add.error.name"}));
                     }else if ("price" in error) {
                        //dispatch(alertActions.error("price: " +error.price[0]));
                        let content_text = <FormattedMessage id="sales.actions.add.error.price"
                                                            defaultMessage="price: {message}"
                                                            values={{message:error.price[0]}}/>
                        dispatch(alertActions.error({title: "sales.actions.add.error.title",
                                                     content:"sales.actions.add.error.price"}));
                    }/* else if ("category" in error) {
                        //dispatch(alertActions.error("category: " + error.category[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.category"
                                                            defaultMessage="category: {message}"
                                                            values={{message:error.category[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.category"}));
                    } */else if ("non_field_errors" in error) {
                        //dispatch(alertActions.error(error.non_field_errors[0]));
                        let content_text = <FormattedMessage id="sales.actions.add.error.non_field_errors"
                                                            defaultMessage="{message}"
                                                            values={{message:error.non_field_errors[0]}}/>
                        dispatch(alertActions.error({title: "sales.actions.add.error.title",
                                                     content:"sales.actions.add.error.non_field_errors"}));
                    }else {
                        //dispatch(alertActions.error(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));
                    }
                } 
            );
    };

    function request() { return { type: saleConstants.ADD_FROM_FILE_REQUEST } }
    function success(sales) { return { type: saleConstants.ADD_FROM_FILE_SUCCESS, sales } }
    function failure(error) { return { type: saleConstants.ADD_FROM_FILE_FAILURE, error } }
}

function retrieveFromPos() {
    return dispatch => {
        dispatch(request());

        saleService.retrieveFromPos()
            .then(
                sales => {
                    dispatch(success(sales))
                    history.push('/');
                    history.push('/sales');

                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: saleConstants.RETRIEVEALL_POS_REQUEST } }
    function success(sales) { return { type: saleConstants.RETRIEVEALL_POS_SUCCESS, sales } }
    function failure(error) { return { type: saleConstants.RETRIEVEALL_POS_FAILURE, error } }
}
