import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderMenu from './Header';
import ButterToast  from 'butter-toast';
import {userActions,paymentActions} from '../_actions'

class Layout extends Component {

  componentDidMount() {

/*     let user = JSON.parse(localStorage.getItem('userIn'));
    if (user && user.token){
       this.props.dispatch(userActions.verify(user.token));
    } */
    this.props.dispatch(userActions.getOrganisation());
    this.props.dispatch(paymentActions.getCustomerMembership());
}

  render() {
    return (
        <HeaderMenu route ={this.props.route} 
                    orgs={this.props.orgs_items} 
                    orgs_options={this.props.orgs_options} 
                    customer = {this.props.customer} 
                    dispatch={this.props.dispatch}>
         <ButterToast position={{ horizontal: 'POS_RIGHT', vertical: 'POS_TOP' }}/>
          {this.props.children}
        </HeaderMenu>
    );
  }
}

function mapStateToProps(state) {
  const { orgs_items,orgs_options } = state.users;
  const { dispatch } = state;
  const { customer } = state.payment;

  return {
      dispatch,
      orgs_items,
      orgs_options,
      customer
  };

}

const connectedLayout = connect(mapStateToProps)(Layout);
export { connectedLayout as Layout };

